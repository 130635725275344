import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import LabelField from "../../Components/labelField";
import SubmitButton from "../../Components/submitButton";
import { useNavigate } from "react-router-dom";
import {
  addQuestionConfig,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getExamSetTypeId,
  getGeneralExamList,
  getPatternList,
  getQuestionConfigList,
} from "../../Redux/Actions/AdminAction";
import PaginationTable from "../../Components/paginationTable";
import InputField from "../../Atoms/InputField";
import {
  containsNumber,
  containsNumber1to100,
  length_One,
  length_Three,
  validateDigitOnly,
  validateNameWithoutSpace,
} from "../../Utils/validators";
import { validateFullNameErrMsg } from "../../Utils/errorMsg";
import { GradeList } from "../../Utils/dataConstant";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import { studentLevelList } from "../../Utils/dataConstant";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import Dropdown from "../../Components/dropdown";
export default function UpdateQuestionConfig() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const [list, setList] = useState<any>([]);
  const [isEditMode, setisEditMode] = useState<any>(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [grade, setGrade] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [toggle, setToggle] = useState<any>(false);
  const [examtype, setExamType] = useState<any>("");
  const [examtypeList, setExamtypeList] = useState<any>([]);
  const [examPattern, setExamPattern] = useState<any>("");
  const [examPatternList, setExamPatternList] = useState([]);
  const [studentLevel, setstudentLevel] = useState<any>("");
  const [question, setQuestion] = useState<any>();
  const [formateTotal, setFormateTotal] = useState<any>("");
  const [priorityTotal, setPriorityTotal] = useState<any>("");
  const [typeTotal, setTypeTotal] = useState<any>("");
  const [examDuration, setExamDuration] = useState<any>("");

  const initialVal: {
    level: any;
    percentage: any;
  }[] = [
    {
      level: 4,
      percentage: "",
    },
    {
      level: 1,
      percentage: "",
    },
    {
      level: 2,
      percentage: "",
    },
    {
      level: 3,
      percentage: "",
    },
  ];
  const [questionFormat, setQuestionFormat] = useState(initialVal);

  const initialQuesType: {
    level: any;
    percentage: any;
  }[] = [
    {
      level: 1,
      percentage: "",
    },
    {
      level: 2,
      percentage: "",
    },
    {
      level: 3,
      percentage: "",
    },
    {
      level: 4,
      percentage: "",
    },
    {
      level: 5,
      percentage: "",
    },
    {
      level: 6,
      percentage: "",
    },
  ];
  const [questionType, setQuestionType] = useState(initialQuesType);
  const initialQuesPriority: {
    level: any;
    percentage: any;
  }[] = [
    {
      level: 1,
      percentage: "",
    },
    {
      level: 2,
      percentage: "",
    },
    {
      level: 3,
      percentage: "",
    },
  ];
  const [questionPriority, setQuestionPriority] = useState(initialQuesPriority);
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );

    getList();
  }, []);

  const getList = () => {
    dispatch(
      getQuestionConfigList(
        {},
        (res: any) => {
          setList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeCountry = (value: any) => {
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value);

    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamtypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setExamType(value);

    dispatch(
      getPatternList(
        {},
        (res: any) => {
          setExamPatternList(res);
        },
        () => {}
      )
    );
  };
  const onChangeExamPattern = (value: { _id: any; name?: string }) => {
    setExamPattern(value);
  };
  const onChangeLevel = (value: { _id: any; name?: string }) => {
    setstudentLevel(value);
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const onChangeQueFormat = (val: any, index: number) => {
    if (!containsNumber1to100(val) && val !== "") {
      dispatch(
        showErrorSnackbar("Only numbers are allowed in between 1 to 100")
      );
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      const newArrr = [...questionFormat];
      newArrr[index]["percentage"] = val;
      let totalQuestionFormate = 0;
      for (let x of newArrr) {
        if (isNaN(x.percentage)) {
          totalQuestionFormate += 0;
        } else {
          totalQuestionFormate += parseInt(x.percentage);
        }
      }
      setQuestionFormat(newArrr);
      setFormateTotal(totalQuestionFormate);
    }
  };
  const onChangeQueType = (val: any, index: number) => {
    if (!containsNumber1to100(val) && val !== "") {
      dispatch(
        showErrorSnackbar("Only numbers are allowed in between 1 to 100")
      );
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      const newArrr = [...questionType];
      newArrr[index]["percentage"] = val;
      let totalQuestionType = 0;
      for (let x of newArrr) {
        if (isNaN(x.percentage)) {
          totalQuestionType += 0;
        } else {
          totalQuestionType += parseInt(x.percentage);
        }
      }
      setQuestionType(newArrr);
      setTypeTotal(totalQuestionType);
    }
  };
  const onChangeQuePriority = (val: any, index: number) => {
    if (!containsNumber1to100(val) && val !== "") {
      dispatch(
        showErrorSnackbar("Only numbers are allowed in between 1 to 100")
      );
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      const newArrr = [...questionPriority];
      newArrr[index]["percentage"] = val;
      let totalQuestionPriority = 0;
      for (let x of newArrr) {
        if (isNaN(x.percentage)) {
          totalQuestionPriority += 0;
        } else {
          totalQuestionPriority += parseInt(x.percentage);
        }
      }
      setQuestionPriority(newArrr);
      setPriorityTotal(totalQuestionPriority);
    }
  };
  const onChangeQuestions = (val: any) => {
    if (!containsNumber(val) && val !== "") {
      dispatch(showErrorSnackbar("Only numbers are allowed"));
      return;
    } else if (!validateNameWithoutSpace(val)) {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
      return;
    } else {
      setQuestion(val);
    }
  };

  const onChangeExamDuration = (val: any) => {
    if (!validateDigitOnly(val) && val !== "") {
      dispatch(showErrorSnackbar("Only numbers are allowed"));
      return;
    } else {
      setExamDuration(val);
    }
  };

  const validatequestionFormat = () => {
    let isQuestionFormatArr: boolean = false;
    questionFormat?.map((item: any) => {
      if (item?.level === "" || item?.percentage === "") {
        isQuestionFormatArr = true;
      }
    });
    return isQuestionFormatArr;
  };

  const validatequestionType = () => {
    let isQuestionTypeArr: boolean = false;
    questionType?.map((item: any) => {
      if (item?.level === "" || item?.percentage === "") {
        isQuestionTypeArr = true;
      }
    });
    return isQuestionTypeArr;
  };

  const validatequestionPriority = () => {
    let isQuestionPriorityArr: boolean = false;
    questionPriority?.map((item: any) => {
      if (item?.level === "" || item?.percentage === "") {
        isQuestionPriorityArr = true;
      }
    });
    return isQuestionPriorityArr;
  };

  const validations = () => {
    return (
      country === "" ||
      course === "" ||
      subject === "" ||
      toggle === false ||
      studentLevel === "" ||
      examtype === "" ||
      examPattern === "" ||
      question === "" ||
      examDuration === "" ||
      validatequestionFormat() ||
      validatequestionPriority() ||
      validatequestionType()
    );
  };
  const handleEdit = (value: any) => {
    setisEditMode(true);
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
          let tempCountry = res?.filter(
            (item: any) => value?.country?._id === item?._id
          );
          setCountry(tempCountry[0]);
        },
        () => {}
      )
    );
    const postData = {
      countryId: value?.country?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
          let tempCourse = res?.filter(
            (item: any) => value?.coursetype?._id === item?._id
          );
          setCourse(tempCourse[0]);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralExamList(
        {
          courseId: value?.coursetype?._id,
        },
        (res: any) => {
          setExamList(res);
          let tempExam = res?.filter(
            (item: any) => value?.examtype?._id === item?._id
          );
          setExam(tempExam[0]);
        },
        () => {}
      )
    );
    let tempGrade = GradeList?.filter(
      (item: any) => value?.grades?._id === item?._id
    );
    setGrade(tempGrade[0]);

    const postdata: any = {
      examtypeId: value?.examtype?._id,
      coursetypeId: value?.coursetype?._id,
      gradeId: value?.grades?._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    dispatch(
      bindSubjectList(
        postdata,
        (res: any) => {
          setSubjectList(res);
          let tempSubject = res?.filter(
            (item: any) => value?.subject?._id === item?._id
          );
          setSubject(tempSubject[0]);
        },
        () => {}
      )
    );
    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamtypeList(res);
          let tempExamSetType = res?.filter(
            (item: any) => value?.examSetType?._id === item?._id
          );
          setExamType(tempExamSetType[0]);
        },
        () => {}
      )
    );
    dispatch(
      getPatternList(
        {},
        (res: any) => {
          setExamPatternList(res);
          let tempExamPattern = res?.filter(
            (item: any) => value?.examPattern?._id === item?._id
          );
          setExamPattern(tempExamPattern[0]);
        },
        () => {}
      )
    );
    let tempstudentLevel = studentLevelList?.filter(
      (item: any) => value?.studentLevel?._id === item?._id
    );
    setstudentLevel(tempstudentLevel[0]);
    setToggle(value?.isActive);
    setQuestionFormat(value?.questionFormat);
    setQuestionType(value?.QuestionType);
    setQuestionPriority(value?.priority);
    setQuestion(value?.totalQuestions);
    setExamDuration(value?.examDuration);
  };

  const handleUpdate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      gradeId: grade?._id,
      subjectId: subject?._id,
      examSetTypeId: examtype?._id,
      totalQuestions: question,
      examPatternId: examPattern?._id,
      isActive: toggle,
      questionFormat: questionFormat,
      QuestionType: questionType,
      priority: questionPriority,
      studentLevelId: studentLevel?._id,
      examDuration: examDuration,
    };
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }
    dispatch(
      addQuestionConfig(
        Postdata,
        (data: any) => {
          setCountry("");
          setCourse("");
          setExam("");
          setGrade("");
          setSubject("");
          setExamType("");
          setExamPattern("");
          setstudentLevel("");
          setQuestionFormat(initialVal);
          setQuestionType(initialQuesType);
          setQuestionPriority(initialQuesPriority);
          setToggle(false);
          setQuestion("");
          setExamDuration("");
          setisEditMode(false);
          getList();
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="p-2 table-responsive">
          <PaginationTable
            tableData={list ? list : []}
            pagename={"QuestionConfig"}
            tableHead={[
              "S.N",
              "Course",
              "Exam",
              "Exam Set",
              "Subject",
              "Pattern",
              "Active",
              "Actions",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
          />
        </div>
      </>
    );
  };

  const renderData = () => {
    return (
      <>
        <div className="row d-flex px-2 mx-0 mt-4 ">
          <div className="col-sm-4">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-md-4">
                <LabelField lableName={"Country"} isRequired />
              </div>
              <div className="col-md-8">
                <Dropdown
                  labelName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-md-4">
                <LabelField lableName={"Course Type"} isRequired />
              </div>
              <div className="col-md-8">
                <Dropdown
                  labelName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                />
              </div>
            </div>
          </div>
          {course?.type === 2 ? (
            <div className="col-sm-4">
              <div className="d-flex mb-2 align-items-center">
                <div className="col-md-4">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-md-8">
                  <Dropdown
                    labelName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    options={GradeList}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {course?.type === 2 || course?.type === 3 ? null : (
            <div className="col-sm-4">
              <div className="d-flex mb-2 align-items-center">
                <div className="col-md-4">
                  <LabelField lableName={"Exam Name "} isRequired />
                </div>
                <div className="col-md-8">
                  <Dropdown
                    labelName="Select Exam Name"
                    value={exam}
                    options={examList}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <div className="col-sm-4">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-md-4">
                <LabelField lableName={"Subject Name"} isRequired />
              </div>
              <div className="col-md-8">
                <Dropdown
                  labelName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-md-4">
                <LabelField lableName={"Exam Type"} isRequired />
              </div>
              <div className="col-md-8">
                <Dropdown
                  labelName="Select Exam Type"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExamType(value)
                  }
                  value={examtype}
                  options={examtypeList}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-md-4">
                <LabelField lableName={"Exam Pattern"} isRequired />
              </div>
              <div className="col-md-8">
                <Dropdown
                  labelName="Select Exam Pattern"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExamPattern(value)
                  }
                  value={examPattern}
                  options={examPatternList}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-md-4">
                <LabelField lableName={"Student Level"} />
              </div>
              <div className="col-md-8">
                <Dropdown
                  labelName="Select Student Level"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeLevel(value)
                  }
                  value={studentLevel}
                  options={studentLevelList}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-4">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-md-4">
                <LabelField lableName={"Active"} />
              </div>
              <div className="ms-2 mt-3">
                <ToggleSwitchbtn
                  getToggleval={(value: any) => {
                    changeToggle(value);
                  }}
                  isActive={toggle}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const renderQuestionFormate = () => {
    return (
      <div className="row m-3 border border-dark py-3 text-start">
        <div className="col-md-12">
          <h4 className="mb-3">{"Question Format"}</h4>
          <div className="d-flex justify-content-between mb-3">
            {questionFormat?.map((item: any, index: any) => {
              return (
                <div className="col-md-3 mb-2 align-items-center">
                  <div className="ps-2 mb-2">
                    <LabelField
                      lableName={
                        item?.level === 1
                          ? "High"
                          : item?.level === 2
                          ? "Medium"
                          : item?.level === 3
                          ? "Low"
                          : item?.level === 4
                          ? "Complex"
                          : ""
                      }
                    />
                  </div>
                  <InputField
                    placeholder="Enter Question Percentage"
                    value={item.percentage}
                    onChangeInput={(value: any) =>
                      onChangeQueFormat(value, index)
                    }
                    isRequired={true}
                  />
                </div>
              );
            })}
          </div>
          {formateTotal === 100 ? (
            ""
          ) : (
            <p className="text-danger">
              * Total Percentage Of Question Format will not be Greater or Less
              than 100
            </p>
          )}
        </div>
      </div>
    );
  };
  const renderQuestiontype = () => {
    return (
      <>
        <div className="row m-3 border border-dark py-3 text-start">
          <h4 className="mb-3">{"Question Type"}</h4>
          <div className="d-flex justify-content-between mb-3">
            {questionType?.map((item: any, index: any) => {
              return (
                <div className="mb-2 align-items-center">
                  <div className="ps-2 mb-2">
                    <LabelField
                      lableName={
                        item?.level === 1
                          ? "MCQ"
                          : item?.level === 2
                          ? "Fixed"
                          : item?.level === 3
                          ? "MSQ"
                          : item?.level === 4
                          ? "DISCRIPTIVE"
                          : item?.level === 5
                          ? "MATCH THE PAIR"
                          : item?.level === 6
                          ? "COADING"
                          : ""
                      }
                    />
                  </div>
                  <InputField
                    placeholder="Enter Percentage"
                    value={item.percentage}
                    onChangeInput={(value: any) =>
                      onChangeQueType(value, index)
                    }
                    isRequired={true}
                  />
                </div>
              );
            })}
          </div>
          {typeTotal === 100 ? (
            ""
          ) : (
            <p className="text-danger">
              * Total Percentage Of Question Type will not be Greater or Less
              than 100
            </p>
          )}
        </div>
      </>
    );
  };
  const renderQuestionPriority = () => {
    return (
      <>
        <div className="row m-3 border border-dark py-3 text-start">
          <h4 className="mb-3">{"Question Priority"}</h4>
          <div className="d-flex justify-content-between mb-3">
            {questionPriority?.map((item: any, index: any) => {
              return (
                <div className="col-md-3 mb-2 align-items-center">
                  <div className="ps-2 mb-2">
                    <LabelField
                      lableName={
                        item?.level === 1
                          ? "High"
                          : item?.level === 2
                          ? "Medium"
                          : item?.level === 3
                          ? "Low"
                          : ""
                      }
                    />
                  </div>
                  <InputField
                    placeholder="Enter Percentage"
                    value={item.percentage}
                    onChangeInput={(value: any) =>
                      onChangeQuePriority(value, index)
                    }
                    isRequired={true}
                  />
                </div>
              );
            })}
          </div>
          {priorityTotal === 100 ? (
            ""
          ) : (
            <p className="text-danger">
              * Total Percentage Of Priority Question will not be Greater or
              Less than 100
            </p>
          )}
        </div>
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <div className="formDiv">
          <form className="text-center" onSubmit={handleUpdate}>
            <div className="col-auto">{renderData()}</div>
            <div className="col-auto">{renderQuestionFormate()}</div>
            <div className="col-auto">{renderQuestiontype()}</div>
            <div className="col-auto">{renderQuestionPriority()}</div>
            <div className="d-flex col-md-12">
              <div className="d-flex  mb-2 align-items-center">
                <div className="col-md-6 ps-4 mb-2">
                  <LabelField lableName={"No of Questions"} />
                </div>
                <InputField
                  placeholder="Enter No of Questions"
                  value={question}
                  onChangeInput={(value: any) => onChangeQuestions(value)}
                  isRequired={true}
                  maxlength={length_Three - 1}
                  minlength={length_One}
                />
              </div>
              <div className="d-flex  mb-2 align-items-center">
                <div className="col-md-6 ps-4 mb-2">
                  <LabelField lableName={"Exam Duration"} />
                </div>
                <InputField
                  placeholder="Enter Exam Duration"
                  value={examDuration}
                  onChangeInput={(value: any) => onChangeExamDuration(value)}
                  isRequired={true}
                  minlength={length_One}
                />
              </div>
            </div>
            <div className="col-md-12 d-flex justify-content-center mt-4">
              <SubmitButton name={"Submit"} validation={validations()} />
            </div>
          </form>
        </div>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT QUESTION CONFIGURATION"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="px-4 mx-0 mt-4 formDiv ">
        {isEditMode ? renderEditMode() : renderListMode()}
      </div>
    </div>
  );
}
