import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  imagePositionList,
  ImageRatioList,
  SeoTextOptions,
} from "../Utils/dataConstant";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import LabelField from "./labelField";
import CustomDropdown from "./customDropdown";
import {
  containsNumber,
  length_Three,
  validateIsDigitsAndDotOnly,
  validateIsFileImageType,
  validateIsFileVideoType,
  validateRating,
  validateValueWithoutSpace,
} from "../Utils/validators";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
  validateIsNumberOnlyErrMsg,
} from "../Utils/errorMsg";
import { uploadSeoImg } from "../Redux/Actions/AdminAction";
import InputField from "../Atoms/InputField";
import DeleteIcon from "@mui/icons-material/Delete";
import Addmorebtn from "./addmorebtn";
import TextCKEditor from "./textCKEditor";
import DatePicker from "./datePicker";

export default function SeoClassContent(props: any) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [menuArray, setmenuArray] = useState<any>();
  const [imageRatio, setImageRatio] = useState<any>("");
  const [altText, setAltText] = useState<any>("");
  const [type, setType] = useState<any>(1);

  const bottomRef: any = useRef(null);
  useEffect(() => {
    setmenuArray(SeoTextOptions);
    props?.getData?.map((value: any) => {
      props?.setClassContentArray(value?.content);

      value?.content?.map((item: any) => {
        const contentObj = JSON.parse(item?.content);
        if (contentObj?.doctype === "video") {
          setType(2);
        } else if (contentObj?.doctype === "img") {
          setType(1);
        }
      });
    });
  }, [props?.getData]);

  const showInputMenu = (inputType: any, data: any) => {
    const index =
      props?.classContentArray?.length > 0
        ? props?.classContentArray[props?.classContentArray?.length - 1]
            ?.contentOrder
        : 0;
    if (inputType === "text") {
      if (data === 7) {
        const initialArr = [
          {
            ColgLogoImg: "",
            ColgLogoAltText: "",
            collegeLogoRatio: "",
          },
        ];
        const inputTypeText = {
          descType: inputType,
          content: JSON.stringify(initialArr),
          contentType: data,
          contentOrder: index + 1,
        };

        props?.setClassContentArray([
          ...props?.classContentArray,
          inputTypeText,
        ]);
      } else if (data === 6) {
        const initialArr = [
          {
            ratingName: "",
            Rating: "",
            RatingDirection: "",
            RatingRatio: "",
            RatingImg: "",
            RatingAltText: "",
          },
        ];
        const inputTypeText = {
          descType: inputType,
          content: JSON.stringify(initialArr),
          contentType: data,
          contentOrder: index + 1,
        };

        props?.setClassContentArray([
          ...props?.classContentArray,
          inputTypeText,
        ]);
      } else if (data === 8 || data === 9 || data === 10 || data === 11) {
        const initialArr = {
          Heading: "",
          SubHeading: "",
          Description: "",
          courseArr: [
            {
              Title: "",
              Desc: "",
              ImgRatio: "",
              Img: "",
              AltText: "",
              CourseTypeName: "",
            },
          ],
        };
        const inputTypeText = {
          descType: inputType,
          content: JSON.stringify(initialArr),
          contentType: data,
          contentOrder: index + 1,
        };
        props?.setClassContentArray([
          ...props?.classContentArray,
          inputTypeText,
        ]);
      } else if (data === 12) {
        const initialArr = [
          {
            Heading: "",
            SubHeading: "",
            Description: "",
            PlatformType: "",
            PlatformLink: "",
            Name: "",
            Date: "",
            Rating: "",
          },
        ];
        const inputTypeText = {
          descType: inputType,
          content: JSON.stringify(initialArr),
          contentType: data,
          contentOrder: index + 1,
        };

        props?.setClassContentArray([
          ...props?.classContentArray,
          inputTypeText,
        ]);
      } else {
        const inputTypeText = {
          descType: inputType,
          content: "",
          contentType: data,
          contentOrder: index + 1,
        };

        props?.setClassContentArray([
          ...props?.classContentArray,
          inputTypeText,
        ]);
      }
    } else if (inputType === "image") {
      const inputTypeImage = {
        descType: inputType,
        content: "",
        contentType: 13,
        contentOrder: index + 1,
        resolution: imageRatio,
        altText: altText,
      };
      props?.setClassContentArray([
        ...props?.classContentArray,
        inputTypeImage,
      ]);
    } else if (inputType === "video") {
      const inputTypeVideo = {
        descType: inputType,
        contentType: 14,
        contentOrder: index + 1,
      };
      props?.setClassContentArray([
        ...props?.classContentArray,
        inputTypeVideo,
      ]);
    }
  };

  function clickText() {
    setShow(!show);
  }

  const handleCloseClassContent = (index: any) => {
    const newArrr = [...props?.classContentArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    props?.setClassContentArray(newArrr);
  };

  const onChangeType = (value: any) => {
    setType(value);
    if (value === 1) {
      let newArrr = JSON.parse(JSON.stringify(props?.classContentArray));
      const contentObj = JSON.parse(newArrr[0].content);
      delete contentObj?.HomeVideo;
      delete contentObj?.doctype;
      delete contentObj?.Direction;
      const convertToStringObj = JSON.stringify(contentObj);
      newArrr[0]["content"] = convertToStringObj;
      props?.setClassContentArray(newArrr);
    } else if (value === 2) {
      let newArrr = JSON.parse(JSON.stringify(props?.classContentArray));
      const contentObj = JSON.parse(newArrr[0].content);
      delete contentObj?.HomeRatio;
      delete contentObj?.Homeimg;
      delete contentObj?.HomeAltText;
      delete contentObj?.Direction;
      delete contentObj?.doctype;
      const convertToStringObj = JSON.stringify(contentObj);
      newArrr[0]["content"] = convertToStringObj;
      props?.setClassContentArray(newArrr);
    }
  };
  const onChangeClassContentText = (
    index: string | number,
    value: any,
    key: string
  ) => {
    let newArrr = JSON.parse(JSON.stringify(props?.classContentArray));
    if (key) {
      newArrr[index][key] = value;
      props?.setClassContentArray(newArrr);
    }
  };

  const onChangeClassContentTextImage = (
    index: string | number,

    value: any,

    key: string
  ) => {
    let newArrr = JSON.parse(JSON.stringify(props?.classContentArray));
    if (key === "img") {
      value.preventDefault();

      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);

        return;
      } else {
        const reader = new FileReader();

        const file = value?.target.files[0];

        reader.readAsDataURL(file);

        dispatch(
          uploadSeoImg(
            file,

            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["img"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
              } else {
                let obj = {
                  text: "",
                  img: res.filePath,
                  ratio: "",
                  doctype: "img",
                  altText: "",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
              }
            },

            () => {}
          )
        );
      }
    } else if (key === "ratio") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["ratio"] = value?.name;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj = {
          text: "",
          img: "",
          ratio: value?.name,
          doctype: "",
          altText: "",
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "altText") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["altText"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj = {
          text: "",
          img: "",
          ratio: "",
          doctype: "",
          altText: value,
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "homeHeading") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["homeHeading"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          Homeimg: "",
          HomeRatio: "",
          doctype: "",
          Direction: "",
          homeDescription: "",
          homeSubHeading: "",
          homeHeading: value,
        };
        if (
          obj["Homeimg"] === null ||
          obj["Homeimg"] === undefined ||
          obj["Homeimg"] === ""
        ) {
          delete obj["Homeimg"];
        }
        if (
          obj["HomeRatio"] === null ||
          obj["HomeRatio"] === undefined ||
          obj["HomeRatio"] === ""
        ) {
          delete obj["HomeRatio"];
        }
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "homeSubHeading") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["homeSubHeading"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          Homeimg: "",
          HomeRatio: "",
          doctype: "",
          Direction: "",
          homeDescription: "",
          homeSubHeading: value,
          homeHeading: "",
        };
        if (
          obj["Homeimg"] === null ||
          obj["Homeimg"] === undefined ||
          obj["Homeimg"] === ""
        ) {
          delete obj["Homeimg"];
        }
        if (
          obj["HomeRatio"] === null ||
          obj["HomeRatio"] === undefined ||
          obj["HomeRatio"] === ""
        ) {
          delete obj["HomeRatio"];
        }
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "homeDescription") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["homeDescription"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          Homeimg: "",
          HomeRatio: "",
          doctype: "",
          Direction: "",
          homeDescription: value,
          homeSubHeading: "",
          homeHeading: "",
        };
        if (
          obj["Homeimg"] === null ||
          obj["Homeimg"] === undefined ||
          obj["Homeimg"] === ""
        ) {
          delete obj["Homeimg"];
        }
        if (
          obj["HomeRatio"] === null ||
          obj["HomeRatio"] === undefined ||
          obj["HomeRatio"] === ""
        ) {
          delete obj["HomeRatio"];
        }
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "Direction") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["Direction"] = value?._id;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          Homeimg: "",
          HomeRatio: "",
          doctype: "",
          Direction: value?.name,
          homeDescription: "",
          homeSubHeading: "",
          homeHeading: "",
        };
        if (
          obj["Homeimg"] === null ||
          obj["Homeimg"] === undefined ||
          obj["Homeimg"] === ""
        ) {
          delete obj["Homeimg"];
        }
        if (
          obj["HomeRatio"] === null ||
          obj["HomeRatio"] === undefined ||
          obj["HomeRatio"] === ""
        ) {
          delete obj["HomeRatio"];
        }
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "HomeRatio") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["HomeRatio"] = value?._id;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          Homeimg: "",
          HomeRatio: value,
          doctype: "",
          Direction: "",
          homeDescription: "",
          homeSubHeading: "",
          homeHeading: "",
        };
        if (
          obj["Homeimg"] === null ||
          obj["Homeimg"] === undefined ||
          obj["Homeimg"] === ""
        ) {
          delete obj["Homeimg"];
        }
        if (
          obj["HomeRatio"] === null ||
          obj["HomeRatio"] === undefined ||
          obj["HomeRatio"] === ""
        ) {
          delete obj["HomeRatio"];
        }
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "Homeimg") {
      value.preventDefault();
      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadSeoImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["Homeimg"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
              } else {
                let obj: any = {
                  Homeimg: res.filePath,
                  HomeRatio: "",
                  doctype: "",
                  Direction: "",
                  homeDescription: "",
                  homeSubHeading: "",
                  homeHeading: "",
                };
                if (
                  obj["Homeimg"] === null ||
                  obj["Homeimg"] === undefined ||
                  obj["Homeimg"] === ""
                ) {
                  delete obj["Homeimg"];
                }
                if (
                  obj["HomeRatio"] === null ||
                  obj["HomeRatio"] === undefined ||
                  obj["HomeRatio"] === ""
                ) {
                  delete obj["HomeRatio"];
                }
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "HomeAltText") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["HomeAltText"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          Homeimg: "",
          HomeRatio: "",
          doctype: "",
          Direction: "",
          homeDescription: "",
          homeSubHeading: "",
          homeHeading: "",
          HomeAltText: value,
        };
        if (
          obj["Homeimg"] === null ||
          obj["Homeimg"] === undefined ||
          obj["Homeimg"] === ""
        ) {
          delete obj["Homeimg"];
        }
        if (
          obj["HomeRatio"] === null ||
          obj["HomeRatio"] === undefined ||
          obj["HomeRatio"] === ""
        ) {
          delete obj["HomeRatio"];
        }
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "HomeVideo") {
      value.preventDefault();

      // setUploading(true);
      if (!validateIsFileVideoType(value)) {
        dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadSeoImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["HomeVideo"] = res.filePath;
                convertObj["doctype"] = "video";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
              } else {
                let obj: any = {
                  HomeVideo: res?.filePath,
                  doctype: "",
                  Direction: "",
                  homeDescription: "",
                  homeSubHeading: "",
                  homeHeading: "",
                  Homeimg: "",
                  HomeRatio: "",
                };
                if (
                  obj["Homeimg"] === null ||
                  obj["Homeimg"] === undefined ||
                  obj["Homeimg"] === ""
                ) {
                  delete obj["Homeimg"];
                }
                if (
                  obj["HomeRatio"] === null ||
                  obj["HomeRatio"] === undefined ||
                  obj["HomeRatio"] === ""
                ) {
                  delete obj["HomeRatio"];
                }
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
                // setUploadText(true);
                // setUploading(false);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "ratingName") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["ratingName"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          ratingName: value,
          Rating: "",
          doctype: "",
          RatingDirection: "",
          RatingImg: "",
          RatingAltText: "",
          RatingRatio: "",
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "Rating") {
      if (value !== "") {
        if (!containsNumber(value)) {
          dispatch(showErrorSnackbar("Only Numbers are allowed!") as any);
          return;
        } else if (!validateValueWithoutSpace(value)) {
          dispatch(showErrorSnackbar(validateIsNumberOnlyErrMsg) as any);
          return;
        } else {
          if (newArrr[index]["content"]) {
            let convertObj = JSON.parse(newArrr[index]["content"]);
            convertObj["Rating"] = value;
            const convertToStringObj = JSON.stringify(convertObj);
            newArrr[index]["content"] = convertToStringObj;
            props?.setClassContentArray(newArrr);
          } else {
            let obj: any = {
              ratingName: "",
              Rating: value,
              doctype: "",
              RatingDirection: "",
              RatingImg: "",
              RatingAltText: "",
              RatingRatio: "",
            };
            const convertToStringObj = JSON.stringify(obj);
            newArrr[index]["content"] = convertToStringObj;
            props?.setClassContentArray(newArrr);
          }
        }
      }
    } else if (key === "RatingDirection") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["RatingDirection"] = value?.name;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          ratingName: "",
          Rating: "",
          doctype: "",
          RatingImg: "",
          RatingAltText: "",
          RatingRatio: value,
          RatingDirection: value?.name,
        };

        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "RatingRatio") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["RatingRatio"] = value?.name;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          ratingName: "",
          Rating: "",
          doctype: "",
          RatingDirection: "",
          RatingImg: "",
          RatingAltText: "",
          RatingRatio: value,
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    } else if (key === "RatingImg") {
      value.preventDefault();
      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadSeoImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["RatingImg"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
              } else {
                let obj: any = {
                  ratingName: "",
                  Rating: "",
                  doctype: "",
                  RatingDirection: "",
                  RatingImg: res.filePath,
                  RatingAltText: "",
                  RatingRatio: "",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                props?.setClassContentArray(newArrr);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "RatingAltText") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["RatingAltText"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      } else {
        let obj: any = {
          ratingName: "",
          Rating: "",
          doctype: "",
          RatingDirection: "",
          RatingImg: "",
          RatingAltText: value,
          RatingRatio: "",
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        props?.setClassContentArray(newArrr);
      }
    }
    // else {
    //   if (newArrr[index][key]) {
    //     let convertObj = JSON.parse(newArrr[index][key]);
    //     convertObj["text"] = value;
    //     const convertToStringObj = JSON.stringify(convertObj);
    //     newArrr[index][key] = convertToStringObj;
    //     props?.setClassContentArray(newArrr);
    //   } else {
    //     let obj = {
    //       text: value,
    //       img: "",
    //       ratio: "",
    //       doctype: "",
    //     };

    //     const convertToStringObj = JSON.stringify(obj);
    //     newArrr[index][key] = convertToStringObj;
    //     props?.setClassContentArray(newArrr);
    //   }
    // }
  };

  function onchangePoints(
    index: number,
    innerIndex: number,
    val: any,
    key: any
  ) {
    let newArr = JSON.parse(JSON.stringify(props?.classContentArray));
    if (key) {
      let arr = newArr[index]["content"].split(" || ");
      arr[innerIndex] = val;
      newArr[index]["content"] = arr.join(" || ");
      props?.setClassContentArray(newArr);
    }
  }

  function onchangecolgLogoData(
    val: any,
    index: any,
    contentindex: any,
    key: any
  ) {
    let newArr = JSON.parse(JSON.stringify(props?.classContentArray));
    if (newArr[index]["content"] === "") {
      const initialArr = [
        {
          ColgLogoImg: "",
          ColgLogoAltText: "",
          collegeLogoRatio: "",
        },
      ];
      newArr[index]["content"] = JSON.stringify(initialArr);
    }
    let arr = JSON.parse(newArr[index]["content"]);
    if (key === "ColgLogoAltText") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "ColgLogoImg") {
      const reader = new FileReader();
      const file = val.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadSeoImg(
          file,
          (res: any) => {
            arr[contentindex][key] = res?.filePath;
            newArr[index]["content"] = JSON.stringify(arr);
            props?.setClassContentArray(newArr);
          },
          () => {}
        )
      );
    } else if (key === "collegeLogoRatio") {
      arr[contentindex][key] = val?._id;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    }
  }

  function onchangeRatingData(
    val: any,
    index: any,
    contentindex: any,
    key: any
  ) {
    let newArr = JSON.parse(JSON.stringify(props?.classContentArray));
    if (newArr[index]["content"] === "") {
      const initialArr = [
        {
          ratingName: "",
          Rating: "",
          RatingDirection: "",
          RatingRatio: "",
          RatingImg: "",
          RatingAltText: "",
        },
      ];
      newArr[index]["content"] = JSON.stringify(initialArr);
    }
    let arr = JSON.parse(newArr[index]["content"]);
    if (key === "ratingName") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Rating") {
      if (
        validateIsDigitsAndDotOnly(val) &&
        val?.length <= 3 &&
        validateRating(val)
      ) {
        arr[contentindex][key] = val;
        newArr[index]["content"] = JSON.stringify(arr);
        props?.setClassContentArray(newArr);
      } else {
        dispatch(
          showErrorSnackbar(
            "Please enter number only and it should be less then 5"
          ) as any
        );
        return;
      }
    } else if (key === "RatingDirection") {
      arr[contentindex][key] = val?._id;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "RatingRatio") {
      arr[contentindex][key] = val?._id;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "RatingImg") {
      const reader = new FileReader();
      const file = val.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadSeoImg(
          file,
          (res: any) => {
            arr[contentindex][key] = res?.filePath;
            newArr[index]["content"] = JSON.stringify(arr);
            props?.setClassContentArray(newArr);
          },
          () => {}
        )
      );
    } else if (key === "RatingAltText") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    }
  }

  function onchangeReviewData(
    index: any,
    val: any,
    contentindex: any,
    key: any
  ) {
    let newArr = JSON.parse(JSON.stringify(props?.classContentArray));
    if (newArr[index]["content"] === "") {
      const initialArr = [
        {
          Heading: "",
          SubHeading: "",
          Description: "",
          PlatformType: "",
          PlatformLink: "",
          Name: "",
          Date: "",
          Rating: "",
        },
      ];
      newArr[index]["content"] = JSON.stringify(initialArr);
    }
    let arr = JSON.parse(newArr[index]["content"]);
    if (key === "Heading") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "SubHeading") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Description") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "PlatformType") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "PlatformLink") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Name") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Date") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Rating") {
      if (
        validateIsDigitsAndDotOnly(val) &&
        val?.length <= 3 &&
        validateRating(val)
      ) {
        arr[contentindex][key] = val;
        newArr[index]["content"] = JSON.stringify(arr);
        props?.setClassContentArray(newArr);
      } else {
        dispatch(
          showErrorSnackbar(
            "Please enter number only and it should be less then 5"
          ) as any
        );
        return;
      }
    }
  }

  function onchangeCourseTypeData(
    val: any,
    index: any,
    contentindex: any,
    key: any
  ) {
    let newArr = JSON.parse(JSON.stringify(props?.classContentArray));
    if (newArr[index]["content"] === "") {
      const initialArr = [
        {
          Heading: "",
          SubHeading: "",
          Description: "",
          courseArr: [
            {
              Title: "",
              Desc: "",
              ImgRatio: "",
              Img: "",
              AltText: "",
              CourseTypeName: "",
            },
          ],
        },
      ];
      newArr[index]["content"] = JSON.stringify(initialArr);
    }
    let arr = JSON.parse(newArr[index]["content"]);
    if (key === "Heading") {
      arr[key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "SubHeading") {
      arr[key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Description") {
      arr[key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "CourseTypeName") {
      arr["courseArr"][contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Title") {
      arr["courseArr"][contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Desc") {
      arr["courseArr"][contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "ImgRatio") {
      arr["courseArr"][contentindex][key] = val?._id;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    } else if (key === "Img") {
      const reader = new FileReader();
      const file = val.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadSeoImg(
          file,
          (res: any) => {
            arr["courseArr"][contentindex][key] = res?.filePath;
            newArr[index]["content"] = JSON.stringify(arr);
            props?.setClassContentArray(newArr);
          },
          () => {}
        )
      );
    } else if (key === "AltText") {
      arr["courseArr"][contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      props?.setClassContentArray(newArr);
    }
  }

  const scrollToBottom = () => {
    if (bottomRef.current) {
      const bottomElement = bottomRef.current.querySelector("#bottomRef");

      if (bottomElement) {
        bottomRef.current.scrollTop = bottomElement.offsetTop;
      }
    }
  };

  const onPressAdd = (index: any) => {
    const newArr = [...props?.classContentArray];
    let arr = newArr[index]["content"].split(" || ");
    arr.push("");
    newArr[index]["content"] = arr.join(" || ");
    props?.setClassContentArray(newArr);
    setTimeout(scrollToBottom, 100);
  };

  const handleClose = (index: any, pointsIndex: number) => {
    const newArr = [...props?.classContentArray];
    if (index > -1) {
      let arr = newArr[index]["content"].split(" || ");
      arr?.splice(pointsIndex, 1);
      newArr[index]["content"] = arr.toString();
    }
    props?.setClassContentArray(newArr);
  };

  useEffect(() => {
    setClassContentValue();
  }, [props?.classContentArray]);

  const setClassContentValue = () => {
    let tempClassContentArray = props?.classContentArray?.map((item: any) => {
      if (item?.descType === "text") {
        return {
          descType: item?.descType,
          content: item.content.replace(/'/g, "&apos;"),
          contentType: item?.contentType,
          contentOrder: item?.contentOrder,
        };
      }
      return item;
    });

    const PostData = {
      classContent: tempClassContentArray,
    };
    props?.setClassContentData(PostData);
    return PostData;
  };

  const renderClassContent = () => {
    return props?.classContentArray
      ?.sort((a: any, b: any) => a.contentOrder - b.contentOrder)
      ?.map((item: any, index: any) => {
        const { descType, content, resolution, contentType, altText } = item;
        let image: any = {};
        if (descType === "image") {
          const filterimage = ImageRatioList?.filter(
            (imgitem: any) => imgitem?.name === resolution
          );
          if (filterimage) {
            image = filterimage[0];
          }
        }
        let textCourseData: any = {};
        if (
          contentType === 8 ||
          contentType === 9 ||
          contentType === 10 ||
          (contentType === 11 && content)
        ) {
          textCourseData = JSON.parse(content);
        }

        // **********************HomeContent**************************

        let textHomeContentData: any = {};
        if (contentType === 5 && content) {
          textHomeContentData = JSON.parse(content);
        }

        // **********************Rating from other panel**************************

        let textRatingData: any = {};
        if (contentType === 6 && content) {
          textRatingData = JSON.parse(content);
        }

        // **********************************************************************

        const onPressAddcourseContent = (index: number) => {
          const newArr = [...props?.classContentArray];
          let arr = JSON.parse(newArr[index]["content"]);
          arr?.courseArr.push({
            Title: "",
            Desc: "",
            ImgRatio: "",
            Img: "",
            AltText: "",
          });
          newArr[index]["content"] = JSON.stringify(arr);
          props?.setClassContentArray(newArr);
        };

        const onPressAddColgLogo = (index: number) => {
          const newArr = [...props?.classContentArray];
          let arr = JSON.parse(newArr[index]["content"]);
          arr.push({
            ColgLogoImg: "",
            ColgLogoAltText: "",
            collegeLogoRatio: "",
          });
          newArr[index]["content"] = JSON.stringify(arr);
          props?.setClassContentArray(newArr);
        };

        const onPressAddRating = (index: number) => {
          const newArr = [...props?.classContentArray];
          let arr = JSON.parse(newArr[index]["content"]);
          arr.push({
            ratingName: "",
            Rating: "",
            RatingDirection: "",
            RatingRatio: "",
            RatingImg: "",
            RatingAltText: "",
          });
          newArr[index]["content"] = JSON.stringify(arr);
          props?.setClassContentArray(newArr);
        };

        const onPressAddReview = (index: number) => {
          const newArr = [...props?.classContentArray];
          let arr = JSON.parse(newArr[index]["content"]);
          arr.push({
            Heading: "",
            SubHeading: "",
            Description: "",
            PlatformType: "",
            PlatformLink: "",
            Name: "",
            Date: "",
            Rating: "",
          });
          newArr[index]["content"] = JSON.stringify(arr);
          props?.setClassContentArray(newArr);
        };

        const handleColgLogoClose = (
          item: any,
          index: any,
          contentIndex: any
        ) => {
          const newArr = [...props?.classContentArray];
          if (index > -1) {
            let arr = JSON.parse(newArr[index]["content"]);
            arr?.splice(contentIndex, 1);
            newArr[index]["content"] = JSON.stringify(arr);
          }
          props?.setClassContentArray(newArr);
        };

        const handleRatingClose = (
          item: any,
          index: any,
          contentIndex: any
        ) => {
          const newArr = [...props?.classContentArray];
          if (index > -1) {
            let arr = JSON.parse(newArr[index]["content"]);
            arr?.splice(contentIndex, 1);
            newArr[index]["content"] = JSON.stringify(arr);
          }
          props?.setClassContentArray(newArr);
        };

        const handleReviewClose = (
          item: any,
          index: any,
          contentIndex: any
        ) => {
          const newArr = [...props?.classContentArray];
          if (index > -1) {
            let arr = JSON.parse(newArr[index]["content"]);
            arr?.splice(contentIndex, 1);
            newArr[index]["content"] = JSON.stringify(arr);
          }
          props?.setClassContentArray(newArr);
        };

        const handleContentClose = (
          item: any,
          index: any,
          contentIndex: any
        ) => {
          const newArr = [...props?.classContentArray];
          if (index > -1) {
            let arr = JSON.parse(newArr[index]["content"]);
            arr?.courseArr?.splice(contentIndex, 1);
            newArr[index]["content"] = JSON.stringify(arr);
          }
          props?.setClassContentArray(newArr);
        };

        const CollegeLogoData = () => {
          return JSON.parse(content)?.map((item: any, contentindex: any) => {
            const { ColgLogoImg, ColgLogoAltText, collegeLogoRatio } = item;
            return (
              <>
                <div className="w-100">
                  {content?.length === 1 ? null : (
                    <span className="text-dark d-flex justify-content-end">
                      <DeleteIcon
                        className="cursor"
                        color="error"
                        fontSize="small"
                        onClick={() =>
                          handleColgLogoClose(item, index, contentindex)
                        }
                      />
                    </span>
                  )}
                  <div className="col-md-12 d-flex Justify-content-between border border-black p-2 row mx-0">
                    <div className="p-3">
                      <div className="col-md-12 d-flex mt-0  p-2">
                        <div className="col-md-4 d-flex py-3">
                          <LabelField lableName={"Image Ratio"} />
                          <CustomDropdown
                            lableName="Select Image Ratio"
                            setInputText={(value: any) =>
                              onchangecolgLogoData(
                                value,
                                index,
                                contentindex,
                                "collegeLogoRatio"
                              )
                            }
                            value={collegeLogoRatio}
                            options={ImageRatioList}
                            defaultValue={
                              ImageRatioList?.length > 0
                                ? collegeLogoRatio
                                : null
                            }
                          />
                        </div>

                        <div className=" col-md-1 border border-3 rounded p-2 ms-2">
                          <ImagePickerAndViewer
                            lableName={"Image"}
                            value={ColgLogoImg}
                            handleChange={(e: any) =>
                              onchangecolgLogoData(
                                e,
                                index,
                                contentindex,
                                "ColgLogoImg"
                              )
                            }
                            removelabel={true}
                          />
                        </div>

                        <div className="col-md-5 d-flex py-2 ms-3">
                          <div className="col-md-4 mt-3">
                            <LabelField lableName={"Alternate Text"} />
                          </div>
                          <InputField
                            placeholder="Enter Alternate Text"
                            value={ColgLogoAltText}
                            onChangeInput={(value: any) =>
                              onchangecolgLogoData(
                                value,
                                index,
                                contentindex,
                                "ColgLogoAltText"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          });
        };

        return (
          <>
            <div
              className="position-relative d-flex justify-content-between m-2 p-2 border"
              key={index}
            >
              {contentType === 1 || contentType === 2 || contentType === 3 ? (
                <div className="m-2 w-100">
                  <label className="fw-bold mb-2 fs-5">
                    {contentType === 1
                      ? "Heading"
                      : contentType === 2
                      ? "Sub-Heading"
                      : contentType === 3
                      ? "Description"
                      : ""}
                  </label>

                  <TextCKEditor
                    value={content}
                    onChange={(value: any) =>
                      onChangeClassContentText(index, value, "content")
                    }
                  />
                </div>
              ) : contentType === 4 ? (
                <div className="w-100">
                  <label className="ms-2 fw-bold mb-2 fs-5">
                    {contentType === 4 ? "Points" : ""}
                  </label>
                  {/* <LabelField lableName={contentType === 4 ? "Points" : ""} /> */}
                  <div className="d-flex Justify-content-between border border-black p-2">
                    <div
                      className="col-6"
                      style={{ maxHeight: "250px", overflow: "auto" }}
                      ref={bottomRef}
                    >
                      {content
                        .split(" || ")
                        ?.map((contentData: any, i: any) => {
                          return (
                            <div
                              className="d-flex border rounded p-1 mt-2"
                              id={
                                i === content?.split(" || ")?.length - 1
                                  ? "bottomRef"
                                  : i
                              }
                            >
                              <span className="px-2">{i + 1} </span>
                              <div className="w-100 d-flex justify-content-between mt-2">
                                <InputField
                                  placeholder="Enter Points"
                                  value={contentData}
                                  onChangeInput={(value: any) =>
                                    onchangePoints(index, i, value, "content")
                                  }
                                />
                              </div>

                              <span className="p-2 mt-2 d-flex removeButton">
                                {content.length === 0 ? null : (
                                  <DeleteIcon
                                    className="cursor"
                                    color="error"
                                    fontSize="small"
                                    onClick={() => handleClose(index, i)}
                                  />
                                )}
                              </span>
                            </div>
                          );
                        })}
                    </div>

                    <div className="col-6">
                      <div className="d-flex justify-content-center">
                        <div className="d-flex justify-content-center mt-3">
                          <Addmorebtn
                            name="Add More"
                            handleClick={() => onPressAdd(index)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : contentType === 5 ? (
                <div className="col-md-10">
                  <label className="ms-2 fw-bold mb-2 fs-5">
                    {contentType === 5 ? "Home Content" : ""}
                  </label>

                  <div className="col-md-12 border border-3 p-4">
                    <div className="d-flex">
                      <div className="col-md-4 d-flex py-2 ms-3">
                        <div className="col-md-3 mt-3">
                          <LabelField lableName={"Heading"} />
                        </div>
                        <InputField
                          placeholder="Enter Heading"
                          value={textHomeContentData?.homeHeading}
                          onChangeInput={(value: any) =>
                            onChangeClassContentTextImage(
                              index,
                              value,
                              "homeHeading"
                            )
                          }
                        />
                      </div>

                      <div className="col-md-5 d-flex py-2 ms-3">
                        <div className="col-md-4 mt-3">
                          <LabelField lableName={"Sub-Heading"} />
                        </div>
                        <InputField
                          placeholder="Enter Sub-Heading"
                          value={textHomeContentData?.homeSubHeading}
                          onChangeInput={(value: any) =>
                            onChangeClassContentTextImage(
                              index,
                              value,
                              "homeSubHeading"
                            )
                          }
                        />
                      </div>
                    </div>

                    <div className="m-2 w-100">
                      <div className="col-md-4 mt-3">
                        <LabelField lableName={"Description"} />
                      </div>

                      <TextCKEditor
                        value={textHomeContentData?.homeDescription}
                        onChange={(value: any) =>
                          onChangeClassContentTextImage(
                            index,
                            value,
                            "homeDescription"
                          )
                        }
                      />
                    </div>
                    <div className="col-5 d-flex ps-3 mt-3">
                      <div className="col-4">
                        <span className="fw-bold">
                          <input
                            type="radio"
                            value={1}
                            name={"type"}
                            checked={type === 1 ? true : false}
                            onChange={() => onChangeType(1)}
                          />{" "}
                          Image
                        </span>
                      </div>
                      <div className="col-6">
                        <span className="fw-bold">
                          <input
                            type="radio"
                            value={2}
                            name={"type"}
                            checked={type === 2 ? true : false}
                            onChange={() => onChangeType(2)}
                          />{" "}
                          Video
                        </span>
                      </div>
                    </div>
                    {type === 1 ? (
                      <div className="border p-3">
                        <div className="col-md-5 d-flex py-3 ">
                          <LabelField lableName={"Image Direction"} />
                          <CustomDropdown
                            lableName="Select Image Direction"
                            setInputText={(value: any) =>
                              onChangeClassContentTextImage(
                                index,
                                value,
                                "Direction"
                              )
                            }
                            value={textHomeContentData?.Direction}
                            defaultValue={
                              imagePositionList?.length > 0
                                ? textHomeContentData?.Direction
                                : null
                            }
                            options={imagePositionList}
                          />
                        </div>
                        <div className="col-md-12 d-flex mt-0  p-2">
                          <div className="col-md-4 d-flex py-3">
                            <LabelField lableName={"Image Ratio"} />
                            <CustomDropdown
                              lableName="Select Image Ratio"
                              setInputText={(value: any) =>
                                onChangeClassContentTextImage(
                                  index,
                                  value,
                                  "HomeRatio"
                                )
                              }
                              value={textHomeContentData?.HomeRatio}
                              defaultValue={
                                ImageRatioList?.length > 0
                                  ? textHomeContentData?.HomeRatio
                                  : null
                              }
                              options={ImageRatioList}
                            />
                          </div>

                          <div className=" col-md-1 border border-3 rounded p-2 ms-2">
                            <ImagePickerAndViewer
                              lableName={"Image"}
                              value={textHomeContentData?.Homeimg}
                              handleChange={(e: any) =>
                                onChangeClassContentTextImage(
                                  index,
                                  e,
                                  "Homeimg"
                                )
                              }
                              removelabel={true}
                            />
                          </div>

                          <div className="col-md-5 d-flex py-2 ms-3">
                            <div className="col-md-4 mt-3">
                              <LabelField lableName={"Alternate Text"} />
                            </div>
                            <InputField
                              placeholder="Enter Alternate Text"
                              value={altText}
                              onChangeInput={(value: any) =>
                                onChangeClassContentTextImage(
                                  index,
                                  value,
                                  "HomeAltText"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {type === 2 ? (
                      <div className="border p-3">
                        <div className="col-md-5 d-flex py-3 ">
                          <LabelField lableName={"Video Direction"} />
                          <CustomDropdown
                            lableName="Select Video Direction"
                            setInputText={(value: any) =>
                              onChangeClassContentTextImage(
                                index,
                                value,
                                "Direction"
                              )
                            }
                            value={textHomeContentData?.Direction}
                            defaultValue={
                              imagePositionList?.length > 0
                                ? textHomeContentData?.Direction
                                : null
                            }
                            options={imagePositionList}
                          />
                        </div>
                        <div className="col-md-12 d-flex mt-0 ms-2  mt-2  p-2">
                          <div className=" d-flex mt-3">
                            <LabelField lableName={"Video"} />
                          </div>
                          <div className="mt-3">
                            <ImagePickerAndViewer
                              lableName={"Video"}
                              value={textHomeContentData?.HomeVideo}
                              handleChange={(value: any) =>
                                onChangeClassContentTextImage(
                                  index,
                                  value,
                                  "HomeVideo"
                                )
                              }
                              doctype={"video"}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : contentType === 6 ? (
                <div className="col-md-10">
                  <label className="ms-2 fw-bold mb-2 fs-5">
                    {contentType === 6 ? "Rating from other panel" : ""}
                  </label>
                  {JSON.parse(content)?.map((item: any, contentindex: any) => {
                    const {
                      ratingName,
                      Rating,
                      RatingDirection,
                      RatingRatio,
                      RatingImg,
                      RatingAltText,
                    } = item;
                    return (
                      <>
                        {content?.length === 1 ? null : (
                          <span className="text-dark d-flex justify-content-end">
                            <DeleteIcon
                              className="cursor"
                              color="error"
                              fontSize="small"
                              onClick={() =>
                                handleRatingClose(item, index, contentindex)
                              }
                            />
                          </span>
                        )}
                        <div className="col-md-12 border border-3 p-4">
                          <div className="d-flex">
                            <div className="col-md-4 d-flex py-2 ms-3">
                              <div className="col-md-3 mt-3">
                                <LabelField lableName={"Name"} />
                              </div>
                              <InputField
                                placeholder="Enter Name"
                                value={ratingName}
                                onChangeInput={(value: any) =>
                                  onchangeRatingData(
                                    value,
                                    index,
                                    contentindex,
                                    "ratingName"
                                  )
                                }
                              />
                            </div>

                            <div className="col-md-4 d-flex py-2 ms-4">
                              <div className="col-md-3 mt-3">
                                <LabelField lableName={"Rating"} />
                              </div>
                              <InputField
                                placeholder="Enter Rating"
                                value={Rating}
                                onChangeInput={(value: any) =>
                                  onchangeRatingData(
                                    value,
                                    index,
                                    contentindex,
                                    "Rating"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="border p-3">
                            <div className="col-md-5 d-flex py-3 ">
                              <LabelField lableName={"Image Direction"} />
                              <CustomDropdown
                                lableName="Select Image Direction"
                                setInputText={(value: any) =>
                                  onchangeRatingData(
                                    value,
                                    index,
                                    contentindex,
                                    "RatingDirection"
                                  )
                                }
                                value={RatingDirection}
                                defaultValue={
                                  imagePositionList?.length > 0
                                    ? RatingDirection
                                    : null
                                }
                                options={imagePositionList}
                              />
                            </div>
                            <div className="col-md-12 d-flex mt-0  p-2">
                              <div className="col-md-4 d-flex py-3">
                                <LabelField lableName={"Image Ratio"} />
                                <CustomDropdown
                                  lableName="Select Image Ratio"
                                  setInputText={(value: any) =>
                                    onchangeRatingData(
                                      value,
                                      index,
                                      contentindex,
                                      "RatingRatio"
                                    )
                                  }
                                  value={RatingRatio}
                                  defaultValue={
                                    ImageRatioList?.length > 0
                                      ? RatingRatio
                                      : null
                                  }
                                  options={ImageRatioList}
                                />
                              </div>

                              <div className=" col-md-1 border border-3 rounded p-2 ms-2">
                                <ImagePickerAndViewer
                                  lableName={"Image"}
                                  value={RatingImg}
                                  handleChange={(e: any) =>
                                    onchangeRatingData(
                                      e,
                                      index,
                                      contentindex,
                                      "RatingImg"
                                    )
                                  }
                                  removelabel={true}
                                />
                              </div>

                              <div className="col-md-5 d-flex py-2 ms-3">
                                <div className="col-md-4 mt-3">
                                  <LabelField lableName={"Alternate Text"} />
                                </div>
                                <InputField
                                  placeholder="Enter Alternate Text"
                                  value={RatingAltText}
                                  onChangeInput={(value: any) =>
                                    onchangeRatingData(
                                      value,
                                      index,
                                      contentindex,
                                      "RatingAltText"
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="d-flex justify-content-end m-2">
                    <Addmorebtn
                      name="Add More"
                      handleClick={() => onPressAddRating(index)}
                    />
                  </div>
                </div>
              ) : contentType === 7 ? (
                <div className="w-100">
                  <label className="ms-2 fw-bold mb-2 fs-5">
                    {contentType === 7 ? "College logos" : ""}
                  </label>
                  {/* <LabelField
                  lableName={contentType === 7 ? "College logos" : ""}
                /> */}
                  <div>{CollegeLogoData()}</div>

                  <div className="d-flex justify-content-end m-2">
                    <Addmorebtn
                      name="Add More"
                      handleClick={() => onPressAddColgLogo(index)}
                    />
                  </div>
                </div>
              ) : contentType === 8 ||
                contentType === 9 ||
                contentType === 10 ||
                contentType === 11 ? (
                <div className="w-100">
                  {/* <LabelField
                  lableName={
                    contentType === 8
                      ? "Course type"
                      : contentType === 9
                      ? "Course Features"
                      : contentType === 10
                      ? "Lurnigo Process"
                      : contentType === 11
                      ? "Lurnigo Stats"
                      : ""
                  }
                /> */}
                  <label className="ms-2 fw-bold mb-2 fs-5">
                    {contentType === 8
                      ? "Course type"
                      : contentType === 9
                      ? "Course Features"
                      : contentType === 10
                      ? "Lurnigo Process"
                      : contentType === 11
                      ? "Lurnigo Stats"
                      : ""}
                  </label>

                  <div className="col-md-12 d-flex Justify-content-between border border-black p-2 row mx-0">
                    <div className="d-flex p-2">
                      <div className="col-md-5 p-2 mt-2">
                        <div className="col-md-8 d-flex py-2 ms-3">
                          <div className="col-md-4 mt-3">
                            <LabelField lableName={"Heading"} />
                          </div>
                          <InputField
                            placeholder="Enter Heading"
                            value={textCourseData?.Heading}
                            onChangeInput={(value: any) =>
                              onchangeCourseTypeData(
                                value,
                                index,
                                "",
                                "Heading"
                              )
                            }
                          />
                        </div>

                        <div className="col-md-8 d-flex py-2 ms-3">
                          <div className="col-md-4 mt-3">
                            <LabelField lableName={"Sub-Heading"} />
                          </div>
                          <InputField
                            placeholder="Enter Sub-Heading"
                            value={textCourseData?.SubHeading}
                            onChangeInput={(value: any) =>
                              onchangeCourseTypeData(
                                value,
                                index,
                                "",
                                "SubHeading"
                              )
                            }
                          />
                        </div>
                      </div>

                      <div className="col-md-7 ">
                        <div className="col-md-4 mt-2">
                          <LabelField lableName={"Description"} />
                        </div>

                        <TextCKEditor
                          value={textCourseData?.Description}
                          onChange={(value: any) =>
                            onchangeCourseTypeData(
                              value,
                              index,
                              "",
                              "Description"
                            )
                          }
                        />
                      </div>
                    </div>
                    {textCourseData?.courseArr?.map(
                      (item: any, contentindex: any) => {
                        const {
                          Title,
                          Desc,
                          ImgRatio,
                          Img,
                          AltText,
                          CourseTypeName,
                        } = item;
                        return (
                          <>
                            {textCourseData?.courseArr?.length === 1 ? null : (
                              <span className="text-dark d-flex justify-content-end">
                                <DeleteIcon
                                  className="cursor"
                                  color="error"
                                  fontSize="small"
                                  onClick={() =>
                                    handleContentClose(
                                      item,
                                      index,
                                      contentindex
                                    )
                                  }
                                />
                              </span>
                            )}
                            <div className="border border-3 p-3 mt-2">
                              <div className="d-flex col-md-12">
                                {contentType === 8 ? (
                                  <div className="col-md-7 d-flex py-2">
                                    <div className="col-md-2 mt-3">
                                      <LabelField lableName={"Course Type"} />
                                    </div>
                                    <InputField
                                      placeholder="Enter Course Type"
                                      value={CourseTypeName}
                                      onChangeInput={(value: any) =>
                                        onchangeCourseTypeData(
                                          value,
                                          index,
                                          contentindex,
                                          "CourseTypeName"
                                        )
                                      }
                                    />
                                  </div>
                                ) : null}
                                <div className="col-md-4 d-flex py-2  ms-3">
                                  <div className="col-md-2 mt-3">
                                    <LabelField lableName={"Title"} />
                                  </div>
                                  <InputField
                                    placeholder="Enter Title"
                                    value={Title}
                                    onChangeInput={(value: any) =>
                                      onchangeCourseTypeData(
                                        value,
                                        index,
                                        contentindex,
                                        "Title"
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-12 d-flex">
                                <div className="col-md-7 mt-1">
                                  <div className=" mt-3">
                                    <LabelField lableName={"Description"} />
                                  </div>

                                  <TextCKEditor
                                    value={Desc}
                                    onChange={(value: any) =>
                                      onchangeCourseTypeData(
                                        value,
                                        index,
                                        contentindex,
                                        "Desc"
                                      )
                                    }
                                  />
                                </div>
                                <div className="col-md-5  mt-3 p-3">
                                  <div className=" d-flex py-3">
                                    <LabelField lableName={"Image Ratio"} />
                                    <CustomDropdown
                                      lableName="Select Image Ratio"
                                      setInputText={(value: any) =>
                                        onchangeCourseTypeData(
                                          value,
                                          index,
                                          contentindex,
                                          "ImgRatio"
                                        )
                                      }
                                      value={ImgRatio}
                                      defaultValue={
                                        ImageRatioList?.length > 0
                                          ? ImgRatio
                                          : null
                                      }
                                      options={ImageRatioList}
                                    />
                                  </div>
                                  <div className="d-flex">
                                    <LabelField lableName={"Upload Image"} />

                                    <div className=" col-md-2 d-flex border border-3 rounded p-2 ms-2">
                                      <ImagePickerAndViewer
                                        lableName={"Image"}
                                        value={Img}
                                        handleChange={(e: any) =>
                                          onchangeCourseTypeData(
                                            e,
                                            index,
                                            contentindex,
                                            "Img"
                                          )
                                        }
                                        removelabel={true}
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex py-2 ms-2">
                                    <div className="col-md-3 mt-3">
                                      <LabelField
                                        lableName={"Alternate Text"}
                                      />
                                    </div>
                                    <InputField
                                      placeholder="Enter Alternate Text"
                                      value={AltText}
                                      onChangeInput={(value: any) =>
                                        onchangeCourseTypeData(
                                          value,
                                          index,
                                          contentindex,
                                          "AltText"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      }
                    )}
                    <div className="d-flex justify-content-end m-2">
                      <Addmorebtn
                        name="Add More"
                        handleClick={() => onPressAddcourseContent(index)}
                      />
                    </div>
                  </div>
                </div>
              ) : contentType === 12 ? (
                <div className="w-100">
                  {/* <LabelField
                  lableName={contentType === 12 ? "Review & Rating" : ""}
                /> */}
                  <label className="ms-2 fw-bold mb-2 fs-5">
                    {contentType === 12 ? "Review & Rating" : ""}
                  </label>
                  {JSON.parse(content)?.map((item: any, contentindex: any) => {
                    const {
                      Heading,
                      SubHeading,
                      Description,
                      PlatformType,
                      PlatformLink,
                      Name,
                      Date,
                      Rating,
                    } = item;
                    return (
                      <>
                        {content?.length === 1 ? null : (
                          <span className="text-dark d-flex justify-content-end">
                            <DeleteIcon
                              className="cursor"
                              color="error"
                              fontSize="small"
                              onClick={() =>
                                handleReviewClose(item, index, contentindex)
                              }
                            />
                          </span>
                        )}
                        <div className="col-md-12 border border-3 p-4">
                          <div className="d-flex">
                            <div className="col-md-4 d-flex py-2 ms-3">
                              <div className="col-md-3 mt-3">
                                <LabelField lableName={"Heading"} />
                              </div>
                              <InputField
                                placeholder="Enter Heading"
                                value={Heading}
                                maxlength={length_Three - 1}
                                onChangeInput={(value: any) =>
                                  onchangeReviewData(
                                    index,
                                    value,
                                    contentindex,
                                    "Heading"
                                  )
                                }
                              />
                            </div>

                            <div className="col-md-5 d-flex py-2 ms-3">
                              <div className="col-md-4 mt-3">
                                <LabelField lableName={"Sub-Heading"} />
                              </div>
                              <InputField
                                placeholder="Enter Sub-Heading"
                                value={SubHeading}
                                onChangeInput={(value: any) =>
                                  onchangeReviewData(
                                    index,
                                    value,
                                    contentindex,
                                    "SubHeading"
                                  )
                                }
                              />
                            </div>
                          </div>

                          <div className="m-2 w-100">
                            <div className="col-md-4 mt-3">
                              <LabelField lableName={"Description"} />
                            </div>

                            <TextCKEditor
                              value={Description}
                              onChange={(value: any) =>
                                onchangeReviewData(
                                  index,
                                  value,
                                  contentindex,
                                  "Description"
                                )
                              }
                            />
                          </div>
                          <div className="d-flex">
                            <div className="col-md-5 d-flex py-2 ms-3">
                              <div className="col-md-4 mt-3">
                                <LabelField lableName={"Platform Type"} />
                              </div>
                              <InputField
                                placeholder="Enter Platform Type"
                                value={PlatformType}
                                onChangeInput={(value: any) =>
                                  onchangeReviewData(
                                    index,
                                    value,
                                    contentindex,
                                    "PlatformType"
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-5 d-flex py-2 ms-3">
                              <div className="col-md-4 mt-3">
                                <LabelField lableName={"Platform Link"} />
                              </div>
                              <InputField
                                placeholder="Enter Platform Link"
                                value={PlatformLink}
                                onChangeInput={(value: any) =>
                                  onchangeReviewData(
                                    index,
                                    value,
                                    contentindex,
                                    "PlatformLink"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="col-md-5 d-flex py-2 ms-3">
                              <div className="col-md-4 mt-3">
                                <LabelField lableName={"Name"} />
                              </div>
                              <InputField
                                placeholder="Enter Name"
                                value={Name}
                                onChangeInput={(value: any) =>
                                  onchangeReviewData(
                                    index,
                                    value,
                                    contentindex,
                                    "Name"
                                  )
                                }
                              />
                            </div>
                            <div className="col-md-5 d-flex py-2 ms-3">
                              <div className="col-md-4 mt-3">
                                <LabelField lableName={"Date"} />
                              </div>
                              <DatePicker
                                value={Date}
                                setInputText={(value: string) =>
                                  onchangeReviewData(
                                    index,
                                    value,
                                    contentindex,
                                    "Date"
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-5 d-flex py-2 ms-4">
                            <div className="col-md-4 mt-3">
                              <LabelField lableName={"Rating"} />
                            </div>
                            <InputField
                              placeholder="Enter Rating"
                              value={Rating}
                              // maxlength={length_Four + 1}
                              // minlength={length_One}
                              onChangeInput={(value: any) =>
                                onchangeReviewData(
                                  index,
                                  value,
                                  contentindex,
                                  "Rating"
                                )
                              }
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="d-flex justify-content-end m-2">
                    <Addmorebtn
                      name="Add More"
                      handleClick={() => onPressAddReview(index)}
                    />
                  </div>
                </div>
              ) : null}
              <div>
                <span className="text-dark">
                  <DeleteIcon
                    className="cursor"
                    color="error"
                    fontSize="small"
                    onClick={() => handleCloseClassContent(index)}
                  />
                </span>
              </div>
            </div>
          </>
        );
      });
  };

  return (
    <>
      <div className="w-100">
        <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
          <h5 className="fw-bold text-light">Class Content</h5>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <span
                className="position-relative addInputbtn"
                onClick={clickText}
              >
                <div className="cursor ms-2 p-1">+ Text</div>
                <div className="position-absolute notiDiv">
                  <div
                    className="position-absolute transperentbg"
                    onClick={() => setShow(false)}
                  ></div>

                  <div
                    className="position-absolute border p-1 font12"
                    style={{
                      background: "#fff",
                      top: "2px",
                      right: "95px",
                      width: "160px",
                      boxShadow: "black -2px 2px 10px -2px",
                    }}
                  >
                    <div className="px-2">
                      {menuArray?.map((c: any) => (
                        <div
                          className="p-2 addQuestionType"
                          style={{ cursor: "pointer" }}
                          onClick={(id: any) => showInputMenu("text", c?._id)}
                        >
                          <span className="colorgray">{c?.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </span>
            </div>
            {/* <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("image", id)}
            >
              + Image
            </div> */}

            {/* <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("video", id)}
            >
              + Video
            </div> */}
          </div>
        </div>

        {renderClassContent()}
      </div>
    </>
  );
}
