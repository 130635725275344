import React from "react";
import { useDispatch } from "react-redux";
import "../App.css";
import moment from "moment";
import parser from "html-react-parser";
import ImagePickerAndViewer from "./imagePickerAndViewer";

interface props {
  tableData: any;
  tableHead: any;
  handleEdit?: any;
  handleEditBatch?: any;
  handleDelete?: any;
  pagename?: any;
  edit?: any;
  addOnButton?: any;
  type?: any;
  className?: any;
  handleAddOnButton?: any;
  addOnButtonLabel?: any;
  handleCheck?: any;
  adddeleteButton?: any;
  handleNavigate?: any;
  handleCheckAll?: any;
  SingleCheck?: any;
}
function AdminTable(props: props) {
  const dispatch = useDispatch();
  const {
    tableData,
    tableHead,
    handleEdit,
    handleEditBatch,
    handleDelete,
    pagename,
    edit,
    addOnButton,
    type,
    className,
    handleAddOnButton,
    addOnButtonLabel,
    handleCheck,
    adddeleteButton,
    handleNavigate,
    handleCheckAll,
    SingleCheck,
  } = props;

  const getSubCategories = (subCategories: any[]) => {
    var categoryArray = subCategories
      .map((item) => {
        return item.name;
      })
      .join(", ");
    return <td className="col-2">{categoryArray}</td>;
  };

  const getBatchNames = (batchNames: any[]) => {
    var batchArray = batchNames
      .map((item) => {
        return item?.name
          ? item?.name
          : item?.fromDate
          ? item?.fromDate
          : item?.sessionDate;
      })
      .join(", ");
    return <td className="col-2">{batchArray}</td>;
  };

  const getSessions = (sessions: any[]) => {
    var filteredArray = sessions.filter((el) => {
      return el.isSelected;
    });
    var sessionArray = filteredArray
      .map((item) => {
        return item.name;
      })
      .join(", ");
    return <td className="col-2">{sessionArray}</td>;
  };

  return (
    <div className="container-fluid pt-3 pe-0 ps-0">
      <table className="w-100 mb-2 adminTable">
        <thead>
          <tr>
            {tableHead?.map(
              (
                data:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | Iterable<React.ReactNode>
                  | React.ReactPortal
                  | null
                  | undefined,
                index: React.Key | null | undefined
              ) => {
                return <th key={index}>{data}</th>;
              }
            )}
          </tr>
        </thead>
        <tbody>
          {Array.isArray(tableData) && tableData.length ? (
            tableData?.map((data, index) => {
              return (
                <>
                  <tr key={index}>
                    {pagename === "studyMaterialOrders" ? (
                      data?._id ? (
                        <td>
                          <span>
                            <input
                              id={data?._id}
                              className="check"
                              type="checkbox"
                              checked={data.isSelected}
                              onChange={(data) => {
                                handleCheck(data, index);
                              }}
                            />
                          </span>
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    <td>{index + 1}</td>
                    {pagename === "ParentCourse" ||
                    pagename === "ExamName" ||
                    pagename === "Language" ||
                    pagename === "subCatagory" ||
                    pagename === "UpdateSection" ||
                    pagename === "subCatagory3" ||
                    pagename === "subCatagory4" ||
                    pagename === "subject" ||
                    pagename === "books" ||
                    pagename === "assignbooks" ||
                    pagename === "section" ||
                    pagename === "assignUnit" ||
                    pagename === "teacher" ||
                    pagename === "couponCode" ||
                    pagename === "topic" ||
                    pagename === "subTopic" ||
                    pagename === "liveRecordedVideo" ||
                    pagename === "package" ||
                    pagename === "packageExtend" ||
                    pagename === "jobType" ||
                    pagename === "UpdateMainCategory" ||
                    pagename === "UpdateLanguage" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "ParentCourse" ? (
                      <td>
                        {data.seoCourseTypeName ? data.seoCourseTypeName : "-"}
                      </td>
                    ) : (
                      ""
                    )}

                    {pagename === "ParentCourse" ? (
                      <td>{data.expertNo ? data.expertNo : "-"}</td>
                    ) : (
                      ""
                    )}
                    {pagename === "ParentCourse" ? (
                      <td>{data.expertMsg ? data.expertMsg : "-"}</td>
                    ) : (
                      ""
                    )}
                    {/* ***************Exam Name*************** */}
                    {pagename === "ExamName" ? (
                      <td>
                        {data.seoExamTypeName ? data.seoExamTypeName : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "ExamName" ? (
                      <td>{data.coursetypeName ? data.coursetypeName : "-"}</td>
                    ) : (
                      ""
                    )}

                    {pagename === "ExamName" ? (
                      data?.examtypeLogo ? (
                        <td>
                          <img
                            src={data?.examtypeLogo}
                            style={{ width: "40px", height: "40px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <div className="ms-3 mt-2">-</div>
                      )
                    ) : (
                      ""
                    )}
                    {/* *****************Language Name**************** */}

                    {pagename === "UpdateLanguage" ? (
                      <td className="text-wrap">
                        {data.abbreviation ? data.abbreviation : "-"}
                      </td>
                    ) : (
                      ""
                    )}
                    {pagename === "UpdateLanguage" ? (
                      data?.languageIcon ? (
                        <td>
                          <img
                            src={data?.languageIcon}
                            style={{ width: "40px", height: "40px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <div className="ms-3 mt-2">-</div>
                      )
                    ) : (
                      ""
                    )}

                    {/* ****************************Sub-Category********************** */}

                    {pagename === "subCatagory" ? (
                      data?.subcategoryIcon ? (
                        <td>
                          <img
                            src={data?.subcategoryIcon}
                            style={{ width: "40px", height: "40px" }}
                            alt={""}
                          />
                        </td>
                      ) : (
                        <div className="ms-3 mt-2">-</div>
                      )
                    ) : (
                      ""
                    )}
                    {pagename === "subCatagory" ? (
                      data?.istax || !data?.istax ? (
                        <td>{data?.istax ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subCatagory" ? (
                      data?.timeType >= 0 ? (
                        <td>
                          {data?.timeType === 1
                            ? "Start Date"
                            : "Video Duration"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "subCatagory" ? (
                      data?.classType === null ? (
                        <td>-</td>
                      ) : data?.classType >= 0 ? (
                        <td>{data?.classType === 1 ? "Live" : "Premier"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* ****************************Section********************** */}

                    {pagename === "UpdateSection" ? (
                      data?.order ? (
                        <td>{data?.order}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "UpdateSection" ? (
                      data?.type ? (
                        <td>{data?.type === 1 ? "Course" : "-"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "UpdateSection" ? (
                      data?.isHomePage || !data?.isHomePage ? (
                        <td>{data?.isHomePage === true ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* ****************************Main-Category********************** */}

                    {pagename === "UpdateMainCategory" ? (
                      data?.isSession || !data?.isSession ? (
                        <td>{data?.isSession === "true" ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "UpdateMainCategory" ? (
                      data?.primaryType ? (
                        <td>{data?.primaryType?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "UpdateMainCategory" ? (
                      data?.subcategoryIds ? (
                        <td>{data?.subcategoryIds[0]?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* **********************************Registration Details********************************************** */}

                    {pagename === "registrationDetails" ? (
                      data?.Exam_Name?.name || data?.Grade_Name?.name ? (
                        <td>
                          {data?.Exam_Name?.name
                            ? data?.Exam_Name?.name
                            : data?.Grade_Name?.name}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ? (
                      data?.Batch_Name?.name ? (
                        <td>{data?.Batch_Name?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ? (
                      data?.course_Activation ? (
                        <td>
                          {data?.course_Activation === true ? "Yes" : "No"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "registrationDetails" ? (
                      data?.End_Date ? (
                        <td>
                          {moment(data?.End_Date).format("DD - MM - YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ********************CouponCode**************** */}
                    {pagename === "CouponCode" ? (
                      data?.couponName ? (
                        <td>{data?.couponName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "CouponCode" ? (
                      data?.discPercentage ? (
                        <td>{data?.discPercentage}%</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "CouponCode" ? (
                      data?.endDate ? (
                        <td>
                          {moment(data?.endDate).format("DD - MM - YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "CouponCode" ? (
                      <td>
                        {data?.isActive === true ? "Activated" : "Deactivated"}
                      </td>
                    ) : null}
                    {pagename === "CouponCode" ? (
                      data?.couponType ? (
                        <td>
                          {data?.couponType === 1 ? "Global" : "Counsellor"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* *********************************DsaDemoRequest******************* */}
                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ||
                    pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ||
                    pagename === "DsaSalesDemoRequest" ||
                    pagename === "DsaSalesDemoSchedule" ||
                    pagename === "DsaSalesDemoConducted" ||
                    pagename === "DsaSalesDemoCancel" ? (
                      data?.firstName ? (
                        <td>{`${data?.firstName} ${data?.lastName}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ||
                    pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ||
                    pagename === "DsaSalesDemoRequest" ||
                    pagename === "DsaSalesDemoSchedule" ||
                    pagename === "DsaSalesDemoConducted" ||
                    pagename === "DsaSalesDemoCancel" ? (
                      data?.countryName ? (
                        <td>{data?.countryName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ? (
                      data?.source ? (
                        <td>{data?.source}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ||
                    pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ||
                    pagename === "DsaSalesDemoRequest" ||
                    pagename === "DsaSalesDemoSchedule" ||
                    pagename === "DsaSalesDemoConducted" ||
                    pagename === "DsaSalesDemoCancel" ? (
                      data?.coursetypeName ? (
                        <td>{data?.coursetypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ||
                    pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ||
                    pagename === "DsaSalesDemoRequest" ||
                    pagename === "DsaSalesDemoSchedule" ||
                    pagename === "DsaSalesDemoConducted" ||
                    pagename === "DsaSalesDemoCancel" ? (
                      data?.createdAt ? (
                        <td>
                          {moment(data?.createdAt).format("DD-MM-YYYY , LTS")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ||
                    pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ||
                    pagename === "DsaSalesDemoRequest" ||
                    pagename === "DsaSalesDemoSchedule" ||
                    pagename === "DsaSalesDemoConducted" ||
                    pagename === "DsaSalesDemoCancel" ? (
                      data?.date ? (
                        <td>{moment(data?.date).format("DD-MM-YYYY")}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ||
                    pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ? (
                      data?.memberName ? (
                        <td>{data?.memberName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ? (
                      data?.DSAManagerName ? (
                        <td>{data?.DSAManagerName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "DsaDemoRequest" ||
                    pagename === "DsaDemoSchedule" ||
                    pagename === "DsaDemoConducted" ||
                    pagename === "DsaDemoCancel" ||
                    pagename === "DsaDemoManagerRequest" ||
                    pagename === "DsaDemoManagerSchedule" ||
                    pagename === "DsaDemoManagerConducted" ||
                    pagename === "DsaDemoManagerCancel" ||
                    pagename === "DsaSalesDemoRequest" ||
                    pagename === "DsaSalesDemoSchedule" ||
                    pagename === "DsaSalesDemoConducted" ||
                    pagename === "DsaSalesDemoCancel" ? (
                      data?.subjectName ? (
                        <td>{data?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* *********************************counsellorPayments******************* */}
                    {pagename === "referenceUserList" ? (
                      data?.firstName ? (
                        <td>{`${data?.firstName} ${data?.lastName}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "referenceUserList" ? (
                      data?.countryName ? (
                        <td>{data?.countryName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "referenceUserList" ? (
                      data?.email ? (
                        <td>{data?.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "referenceUserList" ? (
                      data?.coursetypeName ? (
                        <td>{data?.coursetypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "referenceUserList" ? (
                      data?.parentName ? (
                        <td>{data?.parentName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "referenceUserList" ? (
                      data?.parentEmail ? (
                        <td>{data?.parentEmail}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* *********************************counsellorPayments******************* */}
                    {pagename === "counsellorPaymentList" ? (
                      data?.firstName ? (
                        <td>{`${data?.firstName} ${data?.lastName}`}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "counsellorPayments" ||
                    pagename === "CxoCounsellorPayments" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPayments" ||
                    pagename === "CxoCounsellorPayments" ? (
                      data?.countryName ? (
                        <td>{data?.countryName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPayments" ||
                    pagename === "CxoCounsellorPayments" ? (
                      data?.coursetypeName ? (
                        <td>{data?.coursetypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "CxoCounsellorPayments" ? (
                      data?.counsellorName ? (
                        <td>{data?.counsellorName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPayments" ||
                    pagename === "CxoCounsellorPayments" ||
                    pagename === "counsellorPaymentList" ? (
                      data?.packageName ? (
                        <td>{data?.packageName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPayments" ? (
                      data?.TotalAmount ? (
                        <td>{data?.TotalAmount}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPayments" ||
                    pagename === "CxoCounsellorPayments" ? (
                      data?.couponName ? (
                        <td>{data?.couponName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPayments" ||
                    pagename === "CxoCounsellorPayments" ? (
                      data?.PaymentDate ? (
                        <td>
                          {moment(data?.PaymentDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* *********************************counsellorPayments******************* */}

                    {pagename === "counsellorPaymentList" ? (
                      data?.installmentOptions ||
                      data?.installmentOptions === false ? (
                        <td>{data?.installmentOptions ? "Yes" : "No"}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPaymentList" ? (
                      data?.offerPrice ? (
                        <td>{data?.offerPrice}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPaymentList" ? (
                      data?.couponName ? (
                        <td>{data?.couponName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPaymentList" ? (
                      data?.paymentStatusCode ? (
                        <td>
                          {data?.paymentStatusCode === 1
                            ? "Pending"
                            : data?.paymentStatusCode === 2
                            ? "Link Expired"
                            : data?.paymentStatusCode === 200
                            ? "Successful"
                            : "Failed"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "counsellorPaymentList" ? (
                      data?.paymentDate ? (
                        <td>
                          {moment(data?.paymentDate).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {/* ******************Tutor Details************** */}
                    {pagename === "tutorDetails" ? (
                      data?.date_of_joining ? (
                        <td>
                          {moment(data?.date_of_joining).format("DD-MM-YYYY")}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "tutorDetails" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "tutorDetails" ? (
                      data?.mobile ? (
                        <td>{data?.mobile}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "tutorDetails" ? (
                      data?.email ? (
                        <td>{data?.email}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "tutorDetails" ? (
                      data?.gender ? (
                        <td>{data?.gender}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "tutorDetails" ? (
                      data?.marital_status ? (
                        <td>{data?.marital_status}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "tutorDetails" ? (
                      data?.grade ? (
                        <td>{data?.grade}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "tutorDetails" ? (
                      data?.department ? (
                        <td>{data?.department}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "tutorDetails" ? (
                      <td
                        className="text-success fw-bold cursor"
                        onClick={() => handleNavigate(data)}
                      >
                        View Details
                      </td>
                    ) : null}

                    {/* ******************Upgrade Student Level****************** */}

                    {pagename === "upgradeStudentLevel" ? (
                      data?.country?.name ? (
                        <td>{data?.country?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upgradeStudentLevel" ? (
                      data?.course?.name ? (
                        <td>{data?.course?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upgradeStudentLevel" ? (
                      data?.exam?.name ? (
                        <td>{data?.exam?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upgradeStudentLevel" ? (
                      data?.grade?.name ? (
                        <td>{data?.grade?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upgradeStudentLevel" ? (
                      data?.subject?.name ? (
                        <td>{data?.subject?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "upgradeStudentLevel" ? (
                      data?.studentName ? (
                        <td>{data?.studentName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "upgradeStudentLevel" ? (
                      data?.studentLevel ? (
                        <td>
                          {data?.studentLevel === 1
                            ? "Beginner"
                            : data?.studentLevel === 2
                            ? " Intermediate"
                            : "Advance"}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "upgradeStudentLevel" ? (
                      <td
                        className="text-success fw-bold cursor"
                        onClick={() => handleEdit(data)}
                      >
                        Change Level
                      </td>
                    ) : null}

                    {/* *********************AllUserDetails************************* */}
                    {pagename === "AllUserDetails" ? (
                      data?.name ? (
                        <td>{data?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "AllUserDetails" ? (
                      data?.mobile ? (
                        <td>{data?.mobile}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "AllUserDetails" ? (
                      data?.countryName ? (
                        <td>{data?.countryName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "AllUserDetails" ? (
                      data?.password ? (
                        <td>{data?.password}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {/* ******************************************* */}

                    {pagename === "ImportQuestionBank" ? (
                      data?.questionType?.name ? (
                        <td>{data?.questionType?.name}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "ImportQuestionBank" ? (
                      data?.question ? (
                        <td>
                          {data?.question?.map((value: any) => {
                            return (
                              <>
                                {value?.descType === "text" ? (
                                  <>{parser(value?.description)}</>
                                ) : null}

                                {value?.descType === "image" ? (
                                  <ImagePickerAndViewer
                                    lableName={"Image"}
                                    value={value?.description}
                                    removelabel={true}
                                    disabled
                                  />
                                ) : null}
                              </>
                            );
                          })}
                        </td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "ImportQuestionBank" ? (
                      SingleCheck ? (
                        <td>
                          <span>
                            <input
                              id={data?._id}
                              className="check"
                              type="checkbox"
                              checked={data.isSelected}
                            />
                          </span>
                        </td>
                      ) : (
                        <td></td>
                      )
                    ) : null}

                    {/* ******************Upgrade Student Level****************** */}

                    {pagename === "allignedLectureList" ? (
                      data?.coursetypeName ? (
                        <td>{data?.coursetypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "allignedLectureList" ? (
                      data?.examtypeName ? (
                        <td>{data?.examtypeName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "allignedLectureList" ? (
                      data?.gradedesc ? (
                        <td>{data?.gradedesc}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "allignedLectureList" ? (
                      data?.subjectName ? (
                        <td>{data?.subjectName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}
                    {pagename === "allignedLectureList" ? (
                      data?.lectureName ? (
                        <td>{data?.lectureId}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "allignedLectureList" ? (
                      data?.topicName ? (
                        <td>{data?.topicName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "allignedLectureList" ? (
                      data?.subTopicName ? (
                        <td>{data?.subTopicName}</td>
                      ) : (
                        <td>-</td>
                      )
                    ) : null}

                    {pagename === "allignedLectureList" ? (
                      <td
                        className="text-success fw-bold cursor"
                        onClick={() => handleEdit(data)}
                      >
                        View
                      </td>
                    ) : null}

                    {/* ************************************ */}

                    <td>
                      {addOnButton ? (
                        <>
                          {pagename === "bookhistory" && data?.status === 9 ? (
                            <span
                              className="text-danger fw-bold me-2 cursor"
                              onClick={() => handleAddOnButton(data)}
                            >
                              Entry Deleted
                            </span>
                          ) : (
                            <>
                              <span
                                // type="submit"
                                className={className}
                                onClick={() => handleAddOnButton(data)}
                              >
                                {addOnButtonLabel}{" "}
                              </span>
                              {adddeleteButton ? (
                                <span
                                  // type="submit"
                                  className="text-danger fw-bold cursor"
                                  onClick={() => handleDelete(data)}
                                >
                                  Delete
                                </span>
                              ) : null}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {edit ? (
                            pagename === "userFeedBack" ||
                            pagename === "examResults" ||
                            pagename === "courseFeedBack" ||
                            pagename === "cxobatchwiseCount" ||
                            pagename === "DsaDemoRequest" ||
                            pagename === "DsaDemoSchedule" ||
                            pagename === "DsaDemoConducted" ||
                            pagename === "DsaDemoCancel" ||
                            pagename === "DsaDemoManagerRequest" ||
                            pagename === "DsaDemoManagerSchedule" ||
                            pagename === "DsaDemoManagerConducted" ||
                            pagename === "DsaDemoManagerCancel" ||
                            pagename === "DsaSalesDemoRequest" ||
                            pagename === "DsaSalesDemoSchedule" ||
                            pagename === "DsaSalesDemoConducted" ||
                            pagename === "DsaSalesDemoCancel" ? (
                              // pagename === "batchwiseCount"
                              <span
                                // type="submit"
                                className="text-success me-2 fw-bold cursor"
                                onClick={() => handleEdit(data)}
                              >
                                View Details
                              </span>
                            ) : (
                              <span
                                // type="submit"
                                className="text-primary fw-bold me-2 cursor"
                                onClick={() => handleEdit(data)}
                              >
                                Edit
                              </span>
                            )
                          ) : null}
                          {pagename !== "classroomStudents" &&
                          pagename !== "userFeedBack" &&
                          pagename !== "courseFeedBack" &&
                          pagename !== "importRecVideoLectList" &&
                          pagename !== "TeacherHoursList" &&
                          pagename !== "transactionPublication" &&
                          pagename !== "transactionEdutech" &&
                          pagename !== "studyMaterialOrders" &&
                          pagename !== "AllDetailsSMOrders" &&
                          pagename !== "revenueDetails" &&
                          pagename !== "batchwiseCount" &&
                          pagename !== "datewiseCount" &&
                          pagename !== "admissionHistory" &&
                          pagename !== "studentCount" &&
                          pagename !== "examResults" &&
                          pagename !== "examResultsDetails" &&
                          pagename !== "cxobatchwiseCount" &&
                          pagename !== "registrationDetails" &&
                          pagename !== "DsaSalesDemoRequest" &&
                          pagename !== "DsaSalesDemoSchedule" &&
                          pagename !== "DsaSalesDemoConducted" &&
                          pagename !== "DsaSalesDemoCancel" &&
                          pagename !== "DsaDemoRequest" &&
                          pagename !== "DsaDemoSchedule" &&
                          pagename !== "DsaDemoConducted" &&
                          pagename !== "DsaDemoCancel" &&
                          pagename !== "DsaDemoManagerRequest" &&
                          pagename !== "DsaDemoManagerSchedule" &&
                          pagename !== "DsaDemoManagerConducted" &&
                          pagename !== "DsaDemoManagerCancel" &&
                          pagename !== "counsellorPayments" &&
                          pagename !== "CxoCounsellorPayments" &&
                          pagename !== "tutorDetails" &&
                          pagename !== "upgradeStudentLevel" &&
                          pagename !== "AllUserDetails" &&
                          pagename !== "counsellorPaymentList" &&
                          pagename !== "referenceUserList" &&
                          pagename !== "allignedLectureList" ? (
                            <span
                              // type="submit"
                              className="text-danger fw-bold cursor"
                              onClick={() =>
                                pagename === "editQuestion" ||
                                "notificationList"
                                  ? handleDelete(data)
                                  : handleDelete(data?._id)
                              }
                            >
                              Delete
                            </span>
                          ) : null}
                        </>
                      )}
                    </td>
                  </tr>
                  {pagename === "liveRecVideoLectList" ? (
                    <tr>
                      {data?.courseId ? (
                        <td colSpan={9}>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Batch Name : </div>
                            <div>{getSessions(data?.courseId)}</div>
                            {pagename === "liveRecVideoLectList" ? (
                              <span
                                // type="submit"
                                className="text-primary fw-bold me-2 cursor"
                                onClick={() =>
                                  handleEditBatch(data?.courseId, data?._id)
                                }
                              >
                                Edit
                              </span>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null}
                  {pagename === "editLivePremierVideo" &&
                  (type === 1 || type === 2) ? (
                    <tr>
                      {data?.coursesData ? (
                        <td colSpan={9}>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Batch Name : </div>
                            <div>{getBatchNames(data?.coursesData)}</div>
                            {pagename === "editLivePremierVideo" ? (
                              <span
                                // type="submit"
                                className="text-primary fw-bold me-2 cursor"
                                onClick={() =>
                                  handleEditBatch(data?.coursesData, data?._id)
                                }
                              >
                                Edit
                              </span>
                            ) : null}
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null}

                  {pagename === "editLivePremierVideo" ? (
                    <tr>
                      {data?.classSchedule ? (
                        <td colSpan={9}>
                          <div className="d-flex align-items-center">
                            <div className="fw-bold">Class Date: </div>
                            <div>{getBatchNames(data?.classSchedule)}</div>
                          </div>
                        </td>
                      ) : (
                        <td>-</td>
                      )}
                    </tr>
                  ) : null}
                </>
              );
            })
          ) : (
            <tr>
              <td>No record found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default AdminTable;
