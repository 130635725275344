import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import { useDispatch } from "react-redux";
import {
  addSeoData,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getSeoData,
} from "../../Redux/Actions/AdminAction";
import InputField from "../../Atoms/InputField";
import Dropdown from "../../Components/dropdown";
import SeoClassContent from "../../Components/seoClassContent";
import FaqSeo from "../../Components/faqSeo";
import { GradeList } from "../../Utils/dataConstant";

export default function CreateExamGradeSEO() {
  const dispatch: Function = useDispatch();
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [courseType, setCourseType] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [grade, setGrade] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [slug, setSlug] = useState<any>("");
  const [mTitle, setMTitle] = useState<any>("");
  const [mDescription, setMDescription] = useState<any>("");
  const [contentData, setContentData] = useState<any>([]);
  const [allFaqData, setAllFaqData] = useState<any>([]);
  const [seoDataList, setSeoDataList] = useState<any>([]);
  const [classContentArray, setClassContentArray] = useState<any>([]);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value);
    setSlug("");
    setMTitle("");
    setMDescription("");
    setContentData([]);
    setAllFaqData([]);
    setClassContentArray([]);
    const postdata = {
      countryId: country?._id,
      coursetypeId: courseType?._id,
      examtypeId: value?._id,
      type: "exam",
    };
    dispatch(
      getSeoData(
        postdata,
        (res: any) => {
          setSeoDataList(res);
          res?.map((value: any) => {
            setMDescription(value?.meta_description);
            setMTitle(value?.meta_title);
            setSlug(value?.seo_slug);
          });
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setSlug("");
    setMTitle("");
    setMDescription("");
    setContentData([]);
    setAllFaqData([]);
    setClassContentArray([]);
    const postdata = {
      countryId: country?._id,
      coursetypeId: courseType?._id,
      gradeId: value?._id,
      type: "grade",
    };
    dispatch(
      getSeoData(
        postdata,
        (res: any) => {
          setSeoDataList(res);
          res?.map((value: any) => {
            setMDescription(value?.meta_description);
            setMTitle(value?.meta_title);
            setSlug(value?.seo_slug);
          });
        },
        () => {}
      )
    );
  };

  const onChangeSlug = (value: any) => {
    setSlug(value);
  };
  const onChangeMTitle = (value: any) => {
    setMTitle(value);
  };
  const onChangeMDescription = (value: any) => {
    setMDescription(value);
  };

  const handleClassContentData = (value: any) => {
    setContentData(value?.classContent);
  };

  const handleFaqData = (value: any) => {
    setAllFaqData(value?.faqData);
  };

  const hanldeSubmit = (e: any) => {
    e.preventDefault();
    let FaqAllData = allFaqData?.map((value: any) => {
      return {
        faqTypeId: value?.faqTypeId,
        faqTypeOrderId: value?.faqTypeOrderId,
        faq: value?.faq,
      };
    });
    if (exam) {
      const postdata = {
        countryId: country?._id,
        coursetypeId: courseType?._id,
        seo_slug: slug,
        meta_title: mTitle,
        meta_description: mDescription,
        examtypeId: exam?._id,
        type: "exam",
        content: contentData,
        faqData: FaqAllData,
      };
      dispatch(
        addSeoData(
          postdata,
          (res: any) => {
            setCountry("");
            setCourseType("");
            setExam("");
            setSlug("");
            setMTitle("");
            setMDescription("");
            setContentData([]);
            setAllFaqData([]);
            setClassContentArray([]);
          },
          () => {}
        )
      );
    } else if (grade) {
      const postdata = {
        countryId: country?._id,
        coursetypeId: courseType?._id,
        seo_slug: slug,
        meta_title: mTitle,
        meta_description: mDescription,
        gradeId: grade?._id,
        type: "grade",
        content: contentData,
        faqData: FaqAllData,
      };
      dispatch(
        addSeoData(
          postdata,
          (res: any) => {
            setCountry("");
            setCourseType("");
            setGrade("");
            setSlug("");
            setMTitle("");
            setMDescription("");
            setContentData([]);
            setAllFaqData([]);
            setClassContentArray([]);
          },
          () => {}
        )
      );
    }
  };

  const validateFaq: any = () => {
    let isFaqBlank: boolean = false;
    let ismainfaq: boolean = false;

    allFaqData?.map((item: any) => {
      item?.faq?.map((value: any) => {
        if (
          value?.answer === "" ||
          value?.answer === null ||
          value?.answer === undefined ||
          value?.question === "" ||
          value?.question === null ||
          value?.question === undefined ||
          value?.questionOrderId === "" ||
          value?.questionOrderId === null ||
          value?.questionOrderId === undefined
        ) {
          return (isFaqBlank = true);
        } else {
          return (isFaqBlank = false);
        }
      });
      if (
        item?.faqTypeId === "" ||
        item?.faqTypeId === null ||
        item?.faqTypeId === undefined ||
        isFaqBlank === true
      ) {
        return (ismainfaq = true);
      } else {
        return (ismainfaq = false);
      }
    });
    return ismainfaq;
  };

  const validateContentData: any = () => {
    let isContentData: boolean = false;
    contentData?.map((item: any) => {
      if (
        item?.content === null ||
        item?.content === "" ||
        item?.content === undefined
      ) {
        return (isContentData = true);
      } else {
        return (isContentData = false);
      }
    });
    return isContentData;
  };

  const validation = () => {
    return (
      contentData?.length === 0 ||
      slug === "" ||
      mTitle === "" ||
      mDescription === "" ||
      validateFaq() ||
      validateContentData()
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Exam / Grade SEO"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={hanldeSubmit}>
          <div className="col-12">
            <div className=" col-md-6  d-flex Justify-content-between mt-4 mb-2">
              <div className="col-md-5 align-item-center d-flex">
                <LabelField lableName={"Country"} isRequired />
              </div>
              <div className="col-md-6 ps-1 ">
                <Dropdown
                  labelName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                />
              </div>
            </div>
            <div className=" col-md-6  d-flex Justify-content-between mt-4 mb-2">
              <div className="col-md-5 align-item-center d-flex">
                <LabelField lableName={"Course Type"} isRequired />
              </div>
              <div className="col-md-6 ps-1 ">
                <Dropdown
                  labelName="Select Course Type"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeCourseType(value)
                  }
                  value={courseType}
                  options={courseTypeList}
                />
              </div>
            </div>
            {courseType?.type === 1 ? (
              <div className=" col-md-6  d-flex Justify-content-between mt-4 mb-2">
                <div className="col-md-5 align-item-center d-flex">
                  <LabelField lableName={"Exam"} isRequired />
                </div>
                <div className="col-md-6 ps-1 ">
                  <Dropdown
                    labelName="Select Exam Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    value={exam}
                    options={examList}
                  />
                </div>
              </div>
            ) : null}
            {courseType?.type === 2 ? (
              <div className=" col-md-6  d-flex Justify-content-between mt-4 mb-2">
                <div className="col-md-5 align-item-center d-flex">
                  <LabelField lableName={"Grade"} isRequired />
                </div>
                <div className="col-md-6 ps-1 ">
                  <Dropdown
                    labelName="Select Student Grade"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeStudentGrade(value)
                    }
                    value={grade}
                    options={GradeList}
                  />
                </div>
              </div>
            ) : null}
            <div className=" col-md-6  d-flex Justify-content-between">
              <div className="col-5 align-item-center d-flex">
                <LabelField lableName={"SEO Slug"} isRequired />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter SEO Slug"
                  value={slug}
                  onChangeInput={(value: any) => onChangeSlug(value)}
                />
              </div>
            </div>
            <div className="col-md-6  d-flex Justify-content-between">
              <div className="col-5 align-item-center d-flex">
                <LabelField lableName={"Meta Title"} isRequired />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Meta Title"
                  value={mTitle}
                  onChangeInput={(value: any) => onChangeMTitle(value)}
                />
              </div>
            </div>
            <div className=" col-md-6  d-flex Justify-content-between">
              <div className="col-5 align-item-center d-flex">
                <LabelField lableName={"Meta Description"} isRequired />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Meta Description"
                  value={mDescription}
                  onChangeInput={(value: any) => onChangeMDescription(value)}
                />
              </div>
            </div>

            {/* *********************Class Content*********************** */}
            <div className="border border-black">
              <SeoClassContent
                setClassContentData={(value: any) =>
                  handleClassContentData(value)
                }
                getData={seoDataList}
                contentData={contentData}
                classContentArray={classContentArray}
                setClassContentArray={setClassContentArray}
              />
            </div>
          </div>

          {/* ******************************FAQ******************************* */}

          <FaqSeo
            setFasSeoData={(value: any) => handleFaqData(value)}
            getData={seoDataList}
            allFaqData={allFaqData}
          />

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"ADD"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
