import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  bindSubjectList,
  createQuestionBank,
  getCountryList,
  getCourseTypelist,
  getExamSetTypeId,
  getGeneralExamList,
  getLectureList,
  getSubTopicList,
  getTopicList,
  uploadAcademicsImg,
} from "../../Redux/Actions/AdminAction";
import {
  CodingTypeData,
  GradeList,
  QuestionType,
  complexLevel,
  complexLevelData,
} from "../../Utils/dataConstant";
import { useNavigate } from "react-router-dom";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";
import Addmorebtn from "../../Components/addmorebtn";
import AcademicsHint from "../../Components/academicsHint";
import AcademicsAnswer from "../../Components/academicsAnswer";
import TextCKEditor from "../../Components/textCKEditor";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  validateIsFileImageType,
  validateIsFileVideoType,
} from "../../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
} from "../../Utils/errorMsg";
import AcademicsOption from "../../Components/academicsOption";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import AcademicsMsqOption from "../../Components/academicsMsqOption";
import SubmitButton from "../../Components/submitButton";

export default function QuestionBank() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();

  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [topic, setTopic] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [resetTopic, setResetTopic] = useState(false);
  const [subTopic, setsubTopic] = useState("");
  const [subTopicList, setsubTopicList] = useState([]);
  const [resetsubTopic, setResetsubTopic] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [examType, setExamType] = useState<any>("");
  const [examTypeList, setExamTypeList] = useState<any>();
  const [resetExamType, setResetExamType] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState<any>();
  const [resetBatch, setResetBatch] = useState(false);

  const [lecture, setLecture] = useState<any>("");
  const [lectureList, setLectureList] = useState([]);
  const [resetLectureList, setResetLectureList] = useState(false);

  const [complexity, setComplexity] = useState("");
  const [resetComplexity, setResetComplexity] = useState(false);

  const [questionType, setQuestionType] = useState<any>("");
  const [resetQuestionType, setResetQuestionType] = useState(false);

  const [priorityOrder, setPriorityOrder] = useState("");
  const [resetPriorityOrder, setResetPriorityOrder] = useState(false);

  const [classContentArray, setClassContentArray] = useState<any>([]);
  const [optionArr, setOptionArr] = useState<any[]>([{}]);
  const [mcqHintList, setMcqHintList] = useState<any>([]);
  const [mcqAnsList, setMcqAnsList] = useState<any>([]);
  const [showFixedQue, setShowFixedQue] = useState(false);
  const [codingType, setCodingType] = useState<any>("");

  const initialVal: {
    descType: any;
    description: any;
    isCorrect: any;
    answerType: number;
  }[] = [
      {
        descType: "text",
        description: "",
        isCorrect: "true",
        answerType: 1,
      },
    ];
  const [fixedAnswer, setFixedAnswer] = useState<any>(initialVal);
  const config = {
    loader: { load: ["input/asciimath"] },
    asciimath: {
      displaystyle: true,
      delimiters: [
        ["$", "$"],
        ["`", "`"],
      ],
    },
  };

  useEffect(() => {
    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamTypeList(res);
        },
        () => { }
      )
    );
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setResetExamType(false);
    setExamType(value);
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCourseType(false);
    setResetExam(true);
    setResetGradeList(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
          setResetExam(true);
          setResetSubject(true);
          setResetTopic(true);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: value?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
          setResetSubject(false);
          setResetTopic(true);
        },
        () => { }
      )
    );
    const postdata: any = {
      subjectId: value._id,
    };

    if (courseType?._id != "") {
      postdata["courseId"] = courseType?._id;
    }

    if (grade != "") {
      postdata["gradeId"] = grade?._id;
    }
    if (exam != "") {
      postdata["examtypeId"] = exam;
    }
    dispatch(
      getGeneralPackageList(
        postdata,
        (res: any) => {
          setBatchList(res);
        },
        () => { }
      )
    );
  };
  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value._id);
    setResetTopic(false);
    const data: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setsubTopicList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setsubTopic(value._id);
    setResetsubTopic(false);
    const postdata: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      topicId: topic,
      subTopicId: value._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    if (
      postdata["levelId"] === null ||
      postdata["levelId"] === undefined ||
      postdata["levelId"] === ""
    ) {
      delete postdata["levelId"];
    }
    if (
      postdata["topicId"] === null ||
      postdata["topicId"] === undefined ||
      postdata["topicId"] === ""
    ) {
      delete postdata["topicId"];
    }
    if (
      postdata["subTopicId"] === null ||
      postdata["subTopicId"] === undefined ||
      postdata["subTopicId"] === ""
    ) {
      delete postdata["subTopicId"];
    }
    dispatch(
      getLectureList(
        postdata,
        (res: any) => {
          setLectureList(res);
        },
        () => { }
      )
    );
  };

  const onChangeLecture = (value: { _id: any; name?: string }) => {
    setLecture(value._id);
  };

  const onChangeBatch = (value: { _id: any; name?: string }) => {
    setBatch(value);
    setResetBatch(false);
  };

  const onChangeComplexity = (value: any) => {
    setComplexity(value?._id);
    setResetComplexity(false);
  };

  const onChangeQuestionType = (value: any) => {
    setQuestionType(value?._id);
    setResetQuestionType(false);
    setClassContentArray([]);
    setMcqAnsList([]);
    setMcqHintList([]);
    if (value?._id === 2) {
      setShowFixedQue(true);
    } else {
      setShowFixedQue(false);
    }
  };

  const onChangePriorityOrder = (value: any) => {
    setPriorityOrder(value?._id);
    setResetPriorityOrder(false);
  };

  const showInputMenu = (inputType: any) => {
    const newArrr = [...classContentArray];
    const index = newArrr?.length || 0;
    const newItem = {
      descType: inputType,
      description: "",
      contentOrder: index + 1,
    };
    newArrr.push(newItem);
    setClassContentArray(newArrr);
  };

  const onPressAdd = () => {
    if (optionArr.length < 4) setOptionArr([...optionArr, {}]);
  };

  const storeMcqHint = (val: any) => {
    setMcqHintList(val);
  };
  const storeMcqAnswer = (val: any) => {
    setMcqAnsList(val);
  };

  const onChangeClassContentText = (index: any, value: any, key: string) => {
    const newArrr = [...classContentArray];
    if (key) {
      newArrr[index][key] = value;
      setClassContentArray(newArrr);
    }
  };

  const onChangeClassContentImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            onChangeClassContentText(index, res.filePath, "description");
          },
          () => { }
        )
      );
    }
  };

  const onChangeClassContentVideo = (e: any, index: any) => {
    e.preventDefault();
    if (!validateIsFileVideoType(e)) {
      dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => { };
    reader.readAsDataURL(file);

    dispatch(
      uploadAcademicsImg(
        file,
        (res: any) => {
          if (res) {
            dispatch(showSuccessSnackbar("File Succesfully Uploaded") as any);
            onChangeClassContentText(index, res.filePath, "description");
          } else {
            dispatch(showErrorSnackbar("File Not Uploaded") as any);
          }
        },
        () => { }
      )
    );
  };

  const handleCloseClassContent = (index: any) => {
    const newArrr = [...classContentArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setClassContentArray(newArrr);
  };
  const renderClassContent = () => {
    return classContentArray?.map((item: any, index: any) => {
      const { descType, description } = item;
      return (
        <>
          <div
            className="position-relative d-flex justify-content-between m-2 p-2 border"
            key={index}
          >
            {descType === "text" ? (
              <div className="m-2 w-100">
                <TextCKEditor
                  value={description}
                  onChange={(value: any) =>
                    onChangeClassContentText(index, value, "description")
                  }
                />
              </div>
            ) : null}
            {descType === "image" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Image"}
                  value={description}
                  handleChange={(e: any) => onChangeClassContentImg(e, index)}
                  removelabel={true}
                />
              </div>
            ) : null}
            {descType === "video" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Video"}
                  value={description}
                  handleChange={(value: any) =>
                    onChangeClassContentVideo(value, index)
                  }
                  doctype={"video"}
                />
              </div>
            ) : null}
            <div>
              <span className="text-dark">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleCloseClassContent(index)}
                />
              </span>
            </div>
          </div>
        </>
      );
    });
  };
  const handleOption = (value: {
    descType: string;
    description: string;
    isCorrect: boolean;
    index: number;
  }) => {
    const item = {
      descType: value.descType,
      description: value.description,
      isCorrect: value.isCorrect,
    };
    optionArr[value.index] = item;
  };

  const handleCorrectOption = (value: {
    index: number;
    isCorrect: boolean;
  }) => {
    let newArray = JSON.parse(JSON.stringify(optionArr));
    newArray?.forEach((item: any) => {
      item.isCorrect = false;
    });
    newArray[value.index].isCorrect = value.isCorrect;
    setOptionArr(newArray);
  };

  const handleClose = (index: any) => {
    const newArrr = [...optionArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setOptionArr(newArrr);
  };

  const renderOption = () => {
    return optionArr.map((item, index) => {
      return (
        <>
          <div className="position-relative d-flex justify-content-center p-2">
            <div className="w-100">
              <AcademicsOption
                index={index}
                option={item}
                setOptionData={(value: {
                  descType: string;
                  description: string;
                  isCorrect: boolean;
                  index: number;
                }) => handleOption(value)}
                setCorrectOption={(value: {
                  index: number;
                  isCorrect: boolean;
                }) => handleCorrectOption(value)}
                getData={""}
              />
            </div>
            {optionArr.length === 1 ? null : (
              <span className="text-dark d-flex">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleClose(index)}
                />
              </span>
            )}
          </div>
        </>
      );
    });
  };

  const onChangeFixedAnswer = (index: any, value: any, key: string) => {
    const newArrr = [...fixedAnswer];
    if (key) {
      newArrr[index][key] = value;
      setFixedAnswer(newArrr);
    }
  };

  const handleCorrectMsqOption = (value: any) => {
    let newArray = JSON.parse(JSON.stringify(optionArr));
    if (value?.isCorrect) {
      newArray[value?.index].isCorrect = value?.isCorrect;
      setOptionArr(newArray);
    } else {
      newArray[value?.index].isCorrect = value?.isCorrect;
      setOptionArr(newArray);
    }
  };

  const renderMsqOption = () => {
    return optionArr.map((item, index) => {
      return (
        <>
          <div className="position-relative d-flex justify-content-center p-2">
            <div className="w-100">
              <AcademicsMsqOption
                index={index}
                option={item}
                setOptionData={(value: {
                  descType: string;
                  description: string;
                  isCorrect: boolean;
                  index: number;
                }) => handleOption(value)}
                setCorrectOption={(value: {
                  index: number;
                  isCorrect: boolean;
                }) => handleCorrectMsqOption(value)}
                getData={""}
              />
            </div>
            {optionArr.length === 1 ? null : (
              <span className="text-dark d-flex">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleClose(index)}
                />
              </span>
            )}
          </div>
        </>
      );
    });
  };

  const validation = () => {
    return (
      country === "" ||
      courseType === "" ||
      // exam === "" ||
      // grade === "" ||
      subject === "" ||
      topic === "" ||
      subTopic === "" ||
      lecture === "" ||
      batch === "" ||
      complexity === "" ||
      questionType === ""
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postdata: any = {
      countryId: country?._id,
      coursetypeId: courseType?._id,
      examtypeId: exam,
      gradeId: grade?._id,
      subjectId: subject,
      topicId: topic,
      subTopicId: subTopic,
      lectureId: lecture?._id,
      complexitylevelId: complexity,
      prioritylevelId: priorityOrder,
      type: questionType,
      codingLanguage: codingType?._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["codingLanguage"] === null ||
      postdata["codingLanguage"] === undefined ||
      postdata["codingLanguage"] === ""
    ) {
      delete postdata["codingLanguage"];
    }
    if (questionType === 1 || questionType === 3) {
      postdata["question"] = classContentArray;
      postdata["option"] = optionArr;
      postdata["answer"] = mcqAnsList?.answer;
      postdata["hint"] = mcqHintList?.hint;
    }
    if (questionType === 2) {
      postdata["question"] = classContentArray;
      postdata["option"] = fixedAnswer;
      postdata["answer"] = mcqAnsList?.answer;
      postdata["hint"] = mcqHintList?.hint;
    }
    if (questionType === 4 || questionType === 6) {
      postdata["question"] = classContentArray;
      postdata["answer"] = mcqAnsList?.answer;
      postdata["hint"] = mcqHintList?.hint;
    }

    dispatch(
      createQuestionBank(
        postdata,
        (res: any) => {
          setResetExamType(true);
          setResetCountryList(true);
          setResetCourseType(true);
          setResetExam(true);
          setResetGradeList(true);
          setResetSubject(true);
          setResetTopic(true);
          setResetsubTopic(true);
          setResetLectureList(true);
          setResetBatch(true);
          setResetComplexity(true);
          setResetQuestionType(true);
          setResetPriorityOrder(true);
          setCodingType("");
          setClassContentArray([]);
          setOptionArr([]);
          setMcqAnsList([]);
          setMcqHintList([]);
        },
        () => { }
      )
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"CREATE QUESTION BANK"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form onSubmit={handleSubmit}>
          <div className="border p-2">
            <div className="border mt-4 ">
              <div className="d-flex flex-wrap justify-content-between p-3">
                <div className="col-md-12 d-flex flex-wrap justify-content-between">
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-md-3 align-item-center d-flex">
                      <LabelField lableName={"Exam Type"} />
                    </div>
                    <div className="col-md-7 ps-2">
                      <CustomDropdown
                        lableName="Select Exam Type"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeExamType(value)
                        }
                        value={examType}
                        options={examTypeList}
                        Isrequired={true}
                        reset={resetExamType}
                      />
                    </div>
                  </div>
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Country"} />
                    </div>
                    <div className="col-7 ps-2 ">
                      <CustomDropdown
                        lableName="Select Country"
                        setInputText={(value: any) => onChangeCountry(value)}
                        value={country}
                        options={countryList}
                        Isrequired={true}
                        reset={resetCountryList}
                      />
                    </div>
                  </div>
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Course Type"} />
                    </div>
                    <div className="col-7 ps-2  ">
                      <CustomDropdown
                        lableName="Select Course Type"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeCourseType(value)
                        }
                        value={courseType}
                        options={courseTypeList}
                        reset={resetCourseType}
                        Isrequired={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 d-flex flex-wrap justify-content-between mt-2">
                  {courseType?.type === 2 ? (
                    <div className="d-flex col-md-4 Justify-content-between">
                      <div className="col-3 align-item-center d-flex">
                        <LabelField lableName={"Student Grade"} />
                      </div>
                      <div className="col-7 ps-2 ">
                        <CustomDropdown
                          lableName="Select Student Grade"
                          setInputText={(value: any) =>
                            onChangeStudentGrade(value)
                          }
                          value={grade}
                          Isrequired={true}
                          options={GradeList}
                          reset={resetGradeList}
                        />
                      </div>
                    </div>
                  ) : null}
                  {courseType?.type === 2 || courseType?.type === 3 ? null : (
                    <div className="d-flex col-md-4 Justify-content-between mt-2">
                      <div className="col-3 align-item-center d-flex">
                        <LabelField lableName={"Exam Name"} />
                      </div>
                      <div className="col-7 ps-2 ">
                        <CustomDropdown
                          lableName="Select Exam Name"
                          setInputText={(value: {
                            _id: string;
                            name: string;
                          }) => onChangeExam(value)}
                          value={exam}
                          options={examList}
                          reset={resetExam}
                          Isrequired={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Subject Name"} />
                    </div>
                    <div className="col-7 ps-2 ">
                      <CustomDropdown
                        lableName="Select Subject Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSubject(value)
                        }
                        value={subject}
                        options={subjectList}
                        Isrequired={true}
                        reset={resetSubject}
                      />
                    </div>
                  </div>
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Topic Name"} />
                    </div>
                    <div className="col-7 ps-2 ">
                      <CustomDropdown
                        lableName="Select Topic Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeTopic(value)
                        }
                        value={topic}
                        options={topicList}
                        Isrequired={true}
                        reset={resetTopic}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-md-12 d-flex flex-wrap justify-content-between">
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Sub-Topic Name"} />
                    </div>
                    <div className="col-7 ps-2 ">
                      <CustomDropdown
                        lableName="Select Sub-Topic Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSubTopic(value)
                        }
                        value={subTopic}
                        options={subTopicList}
                        Isrequired={true}
                        reset={resetsubTopic}
                      />
                    </div>
                  </div>
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Lecture Name "} />
                    </div>
                    <div className="col-7 ps-2 ">
                      <CustomDropdown
                        lableName="Select Lecture Name "
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeLecture(value)
                        }
                        value={lecture}
                        options={lectureList}
                        Isrequired={true}
                        reset={resetLectureList}
                      />
                    </div>
                  </div>
                  <div className="d-flex col-md-4 Justify-content-between mt-2">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Batch Name"} />
                    </div>
                    <div className="col-7 ps-2">
                      <CustomDropdown
                        lableName="Select Batch"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeBatch(value)
                        }
                        value={batch}
                        options={batchList}
                        Isrequired={true}
                        reset={resetBatch}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 d-flex flex-wrap justify-content-between mt-5">
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Complexity Level"} />
                </div>
                <div className="col-7 ps-2">
                  <CustomDropdown
                    lableName="Select Complexity Level"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeComplexity(value)
                    }
                    value={complexity}
                    options={complexLevel}
                    Isrequired={true}
                    reset={resetComplexity}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Question Type"} />
                </div>
                <div className="col-7 ps-2">
                  <CustomDropdown
                    lableName="Select Question Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeQuestionType(value)
                    }
                    value={questionType}
                    options={QuestionType}
                    Isrequired={true}
                    reset={resetQuestionType}
                  />
                </div>
              </div>
              <div className="d-flex col-md-4 Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Priority Order"} />
                </div>
                <div className="col-7 ps-2">
                  <CustomDropdown
                    lableName="Select Priority Order"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangePriorityOrder(value)
                    }
                    value={priorityOrder}
                    options={complexLevelData}
                    Isrequired={true}
                    reset={resetPriorityOrder}
                  />
                </div>
              </div>
            </div>

            {questionType === 1 ? (
              <>
                <div className="mt-4 border border-black">
                  <div className="m-2 border border-black">
                    <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-2 mx-2">
                      <h5 className="fw-bold text-light">Question Data</h5>
                      <div className=" d-flex">
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("text")}
                        >
                          + Text
                        </div>
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("image")}
                        >
                          + Image
                        </div>
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("video")}
                        >
                          + Video
                        </div>
                      </div>
                    </div>
                    {renderClassContent()}

                    <div className="mx-2 mb-2 border">
                      <div>{renderOption()}</div>
                      {optionArr.length < 4 ? (
                        <div className="d-flex justify-content-end m-2">
                          <Addmorebtn
                            name="Add New Option"
                            handleClick={onPressAdd}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="m-2 border border-black">
                    <AcademicsHint
                      setMcqHint={(value: any) => storeMcqHint(value)}
                    />
                  </div>

                  <div className="m-2 border border-black">
                    <AcademicsAnswer
                      setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {questionType === 2 || questionType === 4 || questionType === 6 ? (
              <>
                {questionType === 6 ? (
                  <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-4">
                    <div className="d-flex">
                      <h5 className="fw-bold text-light"></h5>
                      {questionType === 6 ? (
                        <div className="d-flex align-items-center">
                          <span className="position-relative addInputbtn">
                            <div className="cursor ms-2 p-1 col-md-12">
                              {codingType
                                ? codingType?.name
                                : "+ Select Coding Type"}
                            </div>

                            <div className="position-absolute notiDivPractice">
                              <div className="position-absolute transperentbg"></div>
                              <div
                                className="position-absolute border p-1 font12"
                                style={{
                                  background: "#fff",
                                  top: "2px",
                                  right: "29px",
                                  width: "160px",
                                  boxShadow: "black -2px 2px 10px -2px",
                                }}
                              >
                                <div className="px-0">
                                  {CodingTypeData?.map((c: any) => (
                                    <div
                                      className="p-2 addQuestionType"
                                      style={{ cursor: "pointer" }}
                                      onClick={() => setCodingType(c)}
                                    >
                                      <span className="ms-3 colorgray ">
                                        {c?.name}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          </span>
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <div className="mt-2 border border-black">
                  <div className="m-2 border border-black">
                    <div className="p-3 bg-dark d-flex align-items-center justify-content-between  mx-2 my-2">
                      <h5 className="fw-bold text-light">Question Data</h5>
                      <div className=" d-flex">
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("text")}
                        >
                          + Text
                        </div>
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("image")}
                        >
                          + Image
                        </div>
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("video")}
                        >
                          + Video
                        </div>
                      </div>
                    </div>
                    {renderClassContent()}
                  </div>
                  <></>
                  {showFixedQue &&
                    fixedAnswer?.map((item: any, index: any) => {
                      const { descType, description, answerType } = item;
                      return (
                        <div className="m-2 border border-black">
                          <div
                            key={index}
                            className={`mb-1 px-3 py-2 border rounded`}
                          >
                            <label className="fw-bold mb-2">Answer</label>
                            <div className="d-flex mb-2">
                              <div className="me-3">
                                <input
                                  type="radio"
                                  name="fixedInput"
                                  onChange={(e: any) =>
                                    onChangeFixedAnswer(index, 1, "answerType")
                                  }
                                  checked={answerType === 1}
                                />{" "}
                                <span>Text</span>
                              </div>
                              <div>
                                <input
                                  type="radio"
                                  name="fixedInput"
                                  onChange={(e: any) =>
                                    onChangeFixedAnswer(index, 2, "answerType")
                                  }
                                  checked={answerType === 2}
                                />{" "}
                                <span>Equation</span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between">
                              <div
                                className="col-md-6"
                                style={{ marginLeft: "1px" }}
                              >
                                <textarea
                                  className="form-control cursor"
                                  value={description}
                                  onChange={(e: any) =>
                                    onChangeFixedAnswer(
                                      index,
                                      e.target.value,
                                      "description"
                                    )
                                  }
                                  style={{ height: "100px" }}
                                />
                              </div>
                              <div
                                className="col-md-6"
                                style={{ marginLeft: "5px" }}
                              >
                                {answerType === 2 && description ? (
                                  <MathJaxContext config={config}>
                                    <MathJax dynamic>
                                      <h6
                                        className="d-flex align-items-center justify-content-center border rounded"
                                        style={{
                                          height: "100px",
                                          overflow: "auto",
                                        }}
                                      >{`$${description}$`}</h6>
                                    </MathJax>{" "}
                                  </MathJaxContext>
                                ) : (
                                  <div>
                                    <h6
                                      className="d-flex align-items-center justify-content-center border rounded"
                                      style={{
                                        height: "100px",
                                        overflow: "auto",
                                      }}
                                    >{`${description}`}</h6>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                  <div className="m-2 border border-black">
                    <AcademicsHint
                      setMcqHint={(value: any) => storeMcqHint(value)}
                    />
                  </div>

                  <div className="m-2 border border-black">
                    <AcademicsAnswer
                      setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {questionType === 3 ? (
              <>
                <div className="mt-2 border border-black">
                  <div className="m-2 border border-black">
                    <div className="p-3 bg-dark d-flex align-items-center justify-content-between mt-2 mx-2">
                      <h5 className="fw-bold text-light">Question Data</h5>
                      <div className=" d-flex">
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("text")}
                        >
                          + Text
                        </div>
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("image")}
                        >
                          + Image
                        </div>
                        <div
                          className="cursor addInputbtn ms-2 p-1"
                          onClick={(id: any) => showInputMenu("video")}
                        >
                          + Video
                        </div>
                      </div>
                    </div>
                    {renderClassContent()}

                    <div className="mx-2 mb-2 border">
                      <div>{renderMsqOption()}</div>
                      {optionArr.length < 4 ? (
                        <div className="d-flex justify-content-end m-2">
                          <Addmorebtn
                            name="Add New Option"
                            handleClick={onPressAdd}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  <div className="m-2 border border-black">
                    <AcademicsHint
                      setMcqHint={(value: any) => storeMcqHint(value)}
                    // getData={props?.getData}
                    />
                  </div>

                  <div className="m-2 border border-black">
                    <AcademicsAnswer
                      setMcqAnswer={(value: any) => storeMcqAnswer(value)}
                    // getData={props?.getData}
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div className=" d-flex justify-content-center mt-3">
              <SubmitButton name={"Submit"} validation={validation()} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
